import { Card, Container, Row, Button, Col } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import "./buspark.css"
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { LeftOutlined, RightOutlined } from "@ant-design/icons"



import { ReactComponent as SeatIcon } from '../../assets/img/icon_seat.svg';
import { ReactComponent as BGIconWhite } from '../../assets/img/bg_white.svg';
import { ReactComponent as WcIconWhite } from '../../assets/img/wc.svg';
import { ReactComponent as TvIconWhite } from '../../assets/img/tv_white.svg';

// Import Swiper styles
import 'swiper/css';
import { Link } from "react-router-dom";
import apiService from "../../services/api.service";
import { useTranslation } from "react-i18next";

const BusItem = ({ item }) => {
    return (<SwiperSlide>
        <Card className={'bus-park-card'}>
            <Card.Img variant="top" src={item.photo} className={'card-img-bus-rent'} />
            <Card.Body style={{padding: "20px 10px"}}>
                <div className={'bus-park-block-flex'}>
                    <div>
                        <Card.Title>{item.name}</Card.Title>
                        <div className='busParkTitleIcons block'>
                            <span>Seats: {item.p.seats}</span>
                        </div>
                        <Link to={'/buspark/' + item.id} className={'link'} >Find out more  <RightOutlined /></Link>
                    </div>
                    <div className={'bus-park-block-icons'}>
                        <div className={'icon-park'}>
                            <WcIconWhite />
                        </div>
                        <div className={'icon-park'}>
                            <TvIconWhite />
                        </div>
                        <div className={'icon-park'}>
                            <BGIconWhite />
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </SwiperSlide>)
}

const BusParkBlock = () => {
    const [buses, setBuses] = useState([]);

    const { t } = useTranslation()
    const [slidesPerView, setslidesPerView] = useState(3);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [space, setSpace] = useState(50);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    useEffect(() => {
        if (width < 600) {
            setslidesPerView(1)
            setSpace(20)
        }
        else if (width >= 600 && width < 992) {
            setslidesPerView(2)
            setSpace(25)
        }
        else {
            setslidesPerView(3)
            setSpace(50)
        }

    }, [width]);

    const getBuses = async () => {
        let resp = await apiService.getBusRent();
        setBuses(resp.data)
    }
    useEffect(() => {
        if (!buses.length) getBuses();
    }, [])

    const swiperRef = useRef();

    return (<section className='bus-park-block' id={"bus-park-block"}>
        <Container>

            <Row style={{justifyContent: 'center'}}>
                <h4 className='text-center'>{t("rentBus")}</h4>

                <Col lg={12} xs={11} >
                    {width > 500 && (
                        <div className={'swiper-btns'}>
                            <div role="button" onClick={() => swiperRef.current.slidePrev()} className={'swiper-btn btn-prev'} >
                                <LeftOutlined />
                            </div>
                            <div role="button" onClick={() => swiperRef.current.slideNext()} className={'swiper-btn btn-next'} >
                                <RightOutlined />
                            </div>
                        </div>
                    )}
                    <Swiper
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        spaceBetween={space}
                        slidesPerView={slidesPerView}
                        loop={true}
                        onSlideChange={() => console.log('slide change')}
                    >
                        {buses.map(item => (
                            <SwiperSlide className={'swiper-slide'} key={item.id}>
                                <BusItem item={item} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Col>

            </Row>
        </Container>
    </section>);
}
export default BusParkBlock;
