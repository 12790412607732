import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./locales/translationEn.json"
import translationRo from "./locales/translationRo.json"
import translationRu from "./locales/translationRu.json"
import translationDe from "./locales/translationDe.json"

if (localStorage.getItem('i18nextLng') === null) {
    localStorage.setItem('i18nextLng', 'de')
}

const resources = {
    en: {
        translation: translationEn
    },
    de: {
        translation: translationDe
    },
    ro: {
        translation: translationRo
    },
    ru: {
        translation: translationRu
    }
};

i18n.use(initReactI18next).init({
    lng: localStorage.getItem('i18nextLng') || "de",
    fallbackLng: "de",
    interpolation: {
        escapeValue: false,
    },
    resources: resources,
});

export default i18n;
