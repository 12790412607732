import React, {useEffect, useState} from 'react';
import {LANGUAGES} from "../../constants";
import {NavDropdown, Navbar, Row, Col, Container, Image, Offcanvas, Nav} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ReactComponent as Ticket} from '../../assets/img/ticket.svg';
import {ReactComponent as IconBus} from '../../assets/img/icon_bus.svg';
import Logo from '../../assets/img/logo_aqua.png';
import {Link} from 'react-router-dom';
import {HashLink} from "react-router-hash-link";

const lang = {
    ru: require('../../assets/img/russia-flag.png'),
    ro: require('../../assets/img/romania-flag.png'),
    en: require('../../assets/img/uk-flag.png'),
    de: require('../../assets/img/de-flag.jpg')
}

const Header = () => {
    const [width, setWidth] = useState(window.innerWidth)

    const updateWidth = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    }, []);

    const {i18n, t} = useTranslation();
    const changeLanguage = (code) => {
        i18n.changeLanguage(code);
        localStorage.setItem('i18nextLng', code.toLowerCase().toString())
    }
    const titleLang = LANGUAGES.filter(lang => lang.code === i18n.resolvedLanguage);
    return (
        <>
            <Navbar bg="light" data-bs-theme="light" expand="lg" style={{padding: '15px 0'}}>
                <Container>
                    <Row style={{width: '100%'}} className='align-items-center'>
                        <div className='w-100 justify-content-between d-flex'>
                            {/* <Col md={4}> */}
                            <Navbar.Brand>
                                <Link to={"/"}>
                                    <Image src={Logo} width={180} />
                                </Link>
                            </Navbar.Brand>
                            {/* </Col> */}
                            {/* <Col md={8} className='d-flex justify-content-end gap-5' style={{height: "100%"}}> */}
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand`}
                                aria-labelledby={`offcanvasNavbarLabel-expand`}
                                placement="end"
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                                        <Image src={Logo} width={180} />
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="justify-content-end align-items-center flex-grow-1 gap-3">
                                        <HashLink smooth to={"#bus-park-block"} className='resend-link d-inline-flex justify-content-center gap-2 align-items-center text-center' style={{width: width > 1024 ? "auto" : "100%"}}>
                                            <IconBus height={25} />
                                            <span>{t("rentBus")}</span>
                                            {/* Resend ticket info */}
                                            {/* <span className='main-link-arrow'></span> */}
                                        </HashLink>
                                        <Link to={"/resend"} className='resend-link d-inline-flex justify-content-center gap-2 align-items-center text-center' style={{width: width > 1024 ? "auto" : "100%"}}>
                                            <Ticket height={25} />
                                            <span>{t("resendTicket")}</span>
                                            {/* Resend ticket info */}
                                            {/* <span className='main-link-arrow'></span> */}
                                        </Link>
                                        <NavDropdown
                                            className={'nav-lang'}
                                            title={
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <Image src={lang[i18n.resolvedLanguage.toString()]} width={20} style={{marginRight: '10px'}} />
                                                    <div>{titleLang[0]?.label}</div>
                                                </div>
                                            }
                                            id="basic-nav-dropdown" align="start"
                                        >
                                            {LANGUAGES.map(({code, label}) => (
                                                <NavDropdown.Item
                                                    className='nav-lang-item'
                                                    key={code}
                                                    onClick={() => {
                                                        changeLanguage(code)
                                                    }}
                                                >
                                                    <Image src={lang[code.toString()]} width={20} />
                                                    {label}
                                                </NavDropdown.Item>
                                            ))}
                                        </NavDropdown>
                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>


                            {/* </Col> */}
                        </div>
                    </Row>
                </Container>
            </Navbar>
        </>
    );
};
export default Header;
