import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Button, Divider } from 'antd';
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTrip } from "../../slices/cart";
import moment from "moment/moment";
import { useTranslation } from 'react-i18next';
import Logo from "../../assets/img/logo_aqua.png";
import {IMG_URL, API_URL} from "../../constants/index";

const formatMoney = (value) => {
    return new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(
        value,
    )
}
const TicketItem = ({ item }) => {
    const [width, setWidth] = useState(window.innerWidth)
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    const { t } = useTranslation()

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const bookBtn = () => {
        dispatch(setTrip(item));
        navigate('/book')
    }
    return (
        <Row className='results-items'>
            <Col md={4}>
                <div className='items-date-block mb-3'>
                    {/* Departing on: */}
                    {t("departing")}:
                    <div className='strong-result-text'><b>{moment(item.departureDate).format('HH:mm')} | {moment(item.departureDate).format('DD MMM YYYY')}</b></div>
                </div>
                <div className='items-direction'>
                    <div className='strong-result-text'><b>{item.departureLocation.name}</b></div>
                    <div>{item.departureLocation.country.name}</div>
                </div>
            </Col>
            <Col md={4} className='items-arrival-block'>
                <ArrowRightOutlined style={{ color: 'var(--base-color-green)' }} />
                <div className='mb-3'>
                    {t("arriving")}:
                    {/* Arrival on: */}
                    <div className='strong-result-text'><b>{moment(item.arrivalDate).format('HH:mm')} | {moment(item.arrivalDate).format('DD MMM YYYY')}</b></div>
                </div>
                <div className='strong-result-text'><b>{item.arrivalLocation.name}</b></div>
                <div>{item.arrivalLocation.country.name}</div>
            </Col>
            <Divider style={{ display: width > 768 && "none", marginTop: 24 }} />
            <Col md={2}>
                <div className='mb-3'>
                    {t("seatsAvailable")}:
                    {/* Seats available: */}
                    <div className='strong-result-text'><b>{item.availableCount}</b></div>
                </div>
                <div className='price-start-from'>
                    {/* Price start from: */}
                    {t("priceStart")}
                </div>
                <div className='price-start-from strong-result-text'><b>{formatMoney(item.prices[0].price_amount)}</b></div>
            </Col>
            <Col md={2} className='d-flex flex-column align-items-center justify-content-center gap-1'>
                <div className='mb-1'>
                    <Image src={IMG_URL + '/uploads/companies/'+item.route.company.logoPath} style={{ objectFit: 'contain', maxWidth: width > 992 ? 150 : 130, width: 'auto', height: '33px' }} />
                </div>
                <div className='mb-1'>
                    {item.route.company.name}
                </div>
                <Button className='book-now-button' onClick={() => bookBtn()}>
                    {/* Book now */}
                    {t("bookNow")}
                </Button>
            </Col>
        </Row>
    )
}
const CargoItem = ({ item }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const bookBtn = () => {
        dispatch(setTrip(item));
        navigate('/book')
    }
    const { t } = useTranslation()
    return (
        <Row className='results-items'>
            <Col md={4}>
                <div className='items-date-block mb-3'>
                    {t("departing")}:
                    {/* Departing on: */}
                    <div className='strong-result-text'><b>{moment(item.departureDate).format('HH:mm')} | {moment(item.departureDate).format('DD MMM YYYY')}</b></div>
                </div>
                <div className='items-direction'>
                    <div className='strong-result-text'><b>{item.departureLocation.name}</b></div>
                    <div>{item.departureLocation.country.name}</div>
                </div>
            </Col>
            <Col md={4} className='items-arrival-block'>
                <ArrowRightOutlined style={{ color: '#094267' }} />
                <div className='mb-3'>
                    {t("arriving")}:
                    {/* Arrival on: */}
                    <div className='strong-result-text'><b>{moment(item.arrivalDate).format('HH:mm')} | {moment(item.arrivalDate).format('DD MMM YYYY')}</b></div>
                </div>
                <div className='strong-result-text'><b>{item.arrivalLocation.name}</b></div>
                <div>{item.arrivalLocation.country.name}</div>
            </Col>
            <Col md={2} className='d-flex flex-column justify-content-center'>
                <div className='price-start-from mb-3'>
                    {t("priceStart")}:
                    {/* Price <br /> start from: */}
                </div>
                <div className='price-start-from strong-result-text'><b>{formatMoney(item.prices[0].price_amount)}</b></div>
            </Col>
            <Col md={2} className='d-flex align-items-center justify-content-center'>
                <Button className='book-now-button' onClick={() => bookBtn()}>
                    {t("bookNow")}
                    {/* Book now */}
                </Button>
            </Col>
        </Row>
    )
}
const SearchItem = ({ item }) => {

    const searchType = useSelector(state => state.search.type);
    return (
        <>
            {searchType === 'cargo' ? <CargoItem item={item} /> : <TicketItem item={item} />}
        </>
    )
}
export default SearchItem
