import React from 'react';
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Seo from '../seo/Seo';
const Home = () => {
    return (<>
        <Seo title='BusLines'
             description='Welcome to BusLines.'
             name='BusLines'
             type='article'
        />
        <Header/>
        <Outlet />
        <Footer />
    </>)
}
export default Home;
