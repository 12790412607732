import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Divider} from "antd";
import {useDispatch, useSelector} from "react-redux";
import PaymentSwitcher from '../../payment/PaymentSwitcher';

import {ReactComponent as TicketStandart} from '../../../assets/img/ticket_standard.svg';
import {ReactComponent as TicketPremium} from '../../../assets/img/ticket_vip.svg';
import {ReactComponent as TicketDisability} from '../../../assets/img/ticket_disability.svg';

import {editPassenger, setPaymentType} from '../../../slices/cart';
import {useTranslation} from 'react-i18next';

const PassengerConfirm = ({setSubmittable, trigger}) => {
    const dispatch = useDispatch();
    const passengers = useSelector(state => state.cart.passengers);
    const reserved = useSelector(state => state.cart.reserved);
    const payment = useSelector((state) => state.cart.payment);
    const seatPassenger = reserved.filter((item, index) => item.id === passengers[index].ticketId);
    const [check, setCheck] = useState(false);
    const {t} = useTranslation()
    useEffect(() => {
        if (check && payment) {
            setSubmittable(true)
        }
        else {setSubmittable(false)}
    }, [check, payment])

    const onChange = (e) => {
        dispatch(setPaymentType(e.target.value))
    };

    return (
        <Container className='cargo-outer passenger-confirm-block'>
            {passengers.map((passenger, index) => (
                <div key={passenger.ticketId}>
                    <Row  className='cargo-outer-row mb-md-3'>
                        <Col md={7}>
                            <div className='d-flex align-items-center cargo-outer-gap'>
                                {seatPassenger[index].seat.category === 'standart' && (<TicketStandart height={52} />)}
                                {seatPassenger[index].seat.category === 'premium' && (<div className='premium-ticket'><TicketPremium height={52} /></div>)}
                                {seatPassenger[index].seat.category === 'disability' && (<div className='disability-ticket'> <TicketDisability height={52} /></div>)}
                                <div className='cargo-confirm-item-info'>
                                    <h3>
                                        {/* Passenger */}
                                        {t("passenger")}
                                    </h3>
                                    <h4>{passenger.name} {passenger.surname}</h4>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className='cargo-confirm-item-info custom-mobile-padding'>
                                <h3>
                                    {/* Class */}
                                    {t("class")}
                                </h3>
                                <h4>{seatPassenger[index].seat.category}</h4>
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className='cargo-confirm-item-info custom-mobile-padding'>
                                <h3>
                                    {/* Seat */}
                                    {t("seat")}
                                </h3>
                                <h4>{seatPassenger[index].seat.number}</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row key={passenger.ticketId} className='cargo-outer-row'>
                        <Col md={7}>
                            <div className='d-flex align-items-center cargo-outer-gap'>
                                <div></div>
                                <div className='cargo-confirm-item-info'>
                                    <h3>
                                        {/* Extra Luggage */}
                                        {t("xLuggage")}
                                    </h3>
                                </div>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className='cargo-confirm-item-info custom-mobile-padding'>
                                <h3>
                                    {/* Quantity */}
                                    {t("qty")}
                                </h3>
                                <h4>{passenger.bagageCount}{" "}
                                    {/* Piece(s) */}
                                    {t("pieces")}
                                </h4>
                            </div>
                        </Col>
                    </Row>
                </div>
            ))}

            <Divider style={{background: '#DFDFE6', height: '2px'}} />

            <PaymentSwitcher check={check} payment={payment} onChange={onChange} setCheck={setCheck} trigger={trigger} />
        </Container>
    )
}
export default PassengerConfirm;
