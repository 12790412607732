import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Divider, Input, InputNumber, Select, Form, Switch } from "antd";
import { PhoneInput } from 'react-international-phone';
import { editPassenger, getPassengerByTicketId } from '../../../slices/cart';
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CargoImg } from '../../../assets/img/cargo.svg';
import { ReactComponent as EmailImg } from '../../../assets/img/icon_mail.svg';
import moment from 'moment';

import { isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js'

import 'react-international-phone/style.css';
import { t } from 'i18next';

const formatMoney = (value) => {
    return new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(
        value,
    )
}

const PassengerForm = ({ item, trigger }) => {
    const dispatch = useDispatch();
    const trip = useSelector(state => state.cart.trip);
    const passenger = useSelector(state => getPassengerByTicketId(state, item.id));
    const [form] = Form.useForm();
    const [day, setDay] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);

    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('md');
    const [isValidated, setValidated] = useState(true);
    const [isValidatedEmail, setValidatedEmail] = useState(true);



    const bagagePrice = () => {
        let element = trip.baggagePrices.find(el => el.key === item.seat.category);
        return formatMoney(element.price_amount)
    }

    const decreaseBagageCount = () => {
        let value = passenger.bagageCount;
        if (value <= 0)
            value = 0
        else
            value = passenger.bagageCount - 1;
        dispatch(editPassenger({
            ticketId: item.id,
            key: 'bagageCount',
            value: value
        }))
    }

    const increaseBagageCount = () => {
        let value = passenger.bagageCount;
        if (value >= 5)
            value = 5
        else
            value = passenger.bagageCount + 1;

        dispatch(editPassenger({
            ticketId: item.id,
            key: 'bagageCount',
            value: value
        }))
    }

    const onSubscribeTicket = (checked) => {
        dispatch(editPassenger({
            ticketId: item.id,
            key: 'subscribe',
            value: checked.toString()
        }))
    };

    const currentYear = new Date().getFullYear();

    useEffect(() => {
        if (day && month && year) {
            const convertDate = moment(`${day}-${month}-${year}`, "DD-MM-YYYY");
            dispatch(editPassenger({
                ticketId: item.id,
                key: 'birthday',
                value: convertDate.isValid() && convertDate.toString()
            }))
        }
    }, [day, month, year]);
    const magicValidator = () => {
        if (!passenger) {
            setValidated(false)
            return false;
        }
        try {
            if (validatePhoneNumberLength(passenger?.phone, country) && isPossiblePhoneNumber(passenger?.phone, country) && isValidPhoneNumber(passenger?.phone, country)) {
                setValidated(true)
            }
            else { setValidated(false) }
        }
        catch (e) { console.error(e) }

        //if(passenger.email) {
            try {
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(passenger.email)) {
                    setValidatedEmail(true)
                } else {
                    setValidatedEmail(false)
                }
            } catch (e) {
                console.error(e)
            }
       // }
    }
    useEffect(() => {
        try {
            magicValidator();
        } catch (e) {
            console.log('error->', e)
        }

    }, [passenger, country, isValidated, trigger])

    // useEffect(() => {

    // }, [passenger.email])


    const downOutlinedIcon = () => {
        return (
            <span className='down-outlined-icon'></span>
        )
    }

    return (<>
    {passenger && (
        <>
        <Form form={form} name={'passengerForm-' + item.seatId} className='common-form' layout="vertical" autoComplete="off"
        >
            <Row className='align-items-end'>
                <Col md={8}>
                    <Form.Item initialValue={passenger.name} name="Name" label={t("name")} validateStatus={trigger && !passenger.name ? "error" : "success"} help={trigger && !passenger.name ? t("nameValidation") : ""}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder={t("name")}
                            onChange={(el) => {
                                dispatch(editPassenger({
                                    ticketId: item.id,
                                    key: 'name',
                                    value: el.target.value
                                }))
                            }} />
                    </Form.Item>
                </Col>
                <Col md={4}>
                    <Form.Item name="Gender" label={t("gender")} initialValue={passenger.gender ? passenger.gender : "male"} validateStatus={trigger && !passenger.gender ? "error" : "success"} help={trigger && !passenger.gender ? t("genderValidation") : ""}
                        // rules={[
                        //     {
                        //         required: true,
                        //     },
                        // ]}
                    >
                        <Select
                            suffixIcon={downOutlinedIcon()}
                            options={[
                                {
                                    value: 'male',
                                    label: t("male"),
                                },
                                {
                                    value: 'female',
                                    label: t("female"),
                                }
                            ]}
                            onChange={(el) => {
                                dispatch(editPassenger({
                                    ticketId: item.id,
                                    key: 'gender',
                                    value: el
                                }))
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className='align-items-end'>
                <Col md={8}>
                    <Form.Item name="Surname" initialValue={passenger.surname} label={t("surname")} validateStatus={trigger && !passenger.surname ? "error" : "success"} help={trigger && !passenger.surname ? t("surnameValidation") : ""}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder={t("surname")}
                            onChange={(el) => {
                                dispatch(editPassenger({
                                    ticketId: item.id,
                                    key: "surname",
                                    value: el.target.value
                                }))
                            }} />
                    </Form.Item>
                </Col>
                <Col md={4}>
                    <Form.Item
                        name="Kind"
                        initialValue={passenger.kind ? passenger.kind : "mature"}
                        // validateStatus={trigger && !passenger.kind ? "error" : "success"}
                        // help={trigger && !passenger.kind ? t("kindValidation") : ""}
                        // rules={[
                        //     {
                        //         required: true,
                        //     },
                        // ]}
                    >
                        <Select
                            suffixIcon={downOutlinedIcon()}
                            options={[
                                {
                                    value: 'mature',
                                    label: t("mature"),
                                },
                                {
                                    value: 'child',
                                    label: t("child"),
                                }
                            ]}
                            onChange={(el) => {
                                dispatch(editPassenger({
                                    ticketId: item.id,
                                    key: 'kind',
                                    value: el
                                }))
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className='align-items-end'>
                <Col md={4}>
                    <Form.Item
                        initialValue={+moment(passenger.birthday).format('D')}
                        name="Day" label={t("dob")}
                        // validateStatus={trigger && !day && !passenger.birthday ? "error" : "success"}
                        // help={trigger && !day && !passenger.birthday ? t("dobValidation") : ""}
                        // rules={[
                        //     {
                        //         required: true,
                        //     },
                        // ]}
                    >
                        <InputNumber placeholder='DD' type='number' min={1} max={31}
                            onChange={(event) => setDay(event)}
                        />
                    </Form.Item>
                </Col>
                <Col md={4}>
                    <Form.Item initialValue={+moment(passenger.birthday).format('M')}
                               name="Month"
                               // validateStatus={trigger && !month && !passenger.birthday ? "error" : "success"}
                        // rules={[
                        //     {
                        //         required: true,
                        //     },
                        // ]}
                    >
                        <InputNumber placeholder='MM' type='number' min={1} max={12}
                            onChange={(event) => setMonth(event)}
                        />
                    </Form.Item>
                </Col>
                <Col md={4}>
                    <Form.Item
                        initialValue={+moment(passenger.birthday).format('YYYY')}
                        name="Years"
                        // validateStatus={trigger && !year && !passenger.birthday ? "error" : "success"}
                        // rules={[
                        //     {
                        //         required: true,
                        //     },
                        // ]}
                    >
                        <InputNumber placeholder='YYYY' type='number' min={1920} max={+currentYear}
                            onChange={(event) => setYear(event)}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className='align-items-end'>
                <Col md={6}>
                    <Form.Item
                        initialValue={passenger.passport}
                        name="Passport"
                        label={t("passport")}
                        // validateStatus={trigger && !passenger.passport ? "error" : "success"}
                        // help={trigger && !passenger.passport ? t("passportValidation") : ""}
                        // rules={[
                        //     {
                        //         required: true,
                        //     },
                        // ]}
                    >
                        <Input placeholder={t("passport")}
                            onChange={(el) => {
                                dispatch(editPassenger({
                                    ticketId: item.id,
                                    key: "passport",
                                    value: el.target.value
                                }))
                            }} />
                    </Form.Item>
                </Col>
                <Col md={6}>
                    <Form.Item name="Mobile" label={t("mphone")} initialValue={passenger.phone}
                        validateStatus="error"
                        help={trigger && !isValidated ? t("phoneValidation") : ''}
                        // rules={[
                        //     {
                        //         required: true,
                        //     },
                        // ]}
                    >
                        <PhoneInput
                            className={trigger && !isValidated ? 'phone-not-valid' : ''}
                            placeholder="+123 (12) 34-56-78"
                            defaultCountry={country}
                            dropdownArrowStyle={{ display: 'none' }}
                            value={passenger.phone}
                            onChange={(phone, country) => {
                                setCountry(country)
                                setPhone(phone)
                                dispatch(editPassenger({
                                    ticketId: item.id,
                                    key: "phone",
                                    value: phone
                                }))
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className='align-items-end'>
                <Col md={6}>
                    <Form.Item initialValue={passenger.email}
                               name="Email" label={t("email")}
                               validateStatus={trigger && !isValidatedEmail ? "error" : "success"}
                               help={trigger && !isValidatedEmail ? t("emailInputError") : ""}
                               rules={[
                                    {
                                        type: "email",
                                        message: t("emailInputError")
                                    },
                                    {
                                        required: true,
                                        message: t("emailValidation")
                                    }
                               ]}
                    >
                        <Input placeholder={t("email")}
                            onChange={(el) => {
                                dispatch(editPassenger({
                                    ticketId: item.id,
                                    key: "email",
                                    value: el.target.value
                                }))
                            }} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>

        <Divider style={{ background: '#999999' }} />

        <Row className='extra-info-block d-flex align-items-center mb-4'>
            <Col md={8} className='d-flex align-items-center'>
                <EmailImg className='email-icon-svg' />
                <p className='extra-luggage d-flex flex-column'>
                    {t("subscribeForTickets")}
                    {/* Send tickets details on Email */}
                    </p>
            </Col>
            <Col md={4} className='switch-subscribe d-flex align-items-center justify-content-end'>
                <Switch defaultChecked onChange={onSubscribeTicket} />
            </Col>
        </Row>

        <Row className='extra-info-block d-flex align-items-center'>
            <Col md={8} className='d-flex align-items-center'>
                <CargoImg style={{ transform: 'scale(1.3)'}} />
                <p className='extra-luggage d-flex flex-column'>
                    {/* Extra luggage: */}
                    {t("xLuggage")}:
                    <span>{bagagePrice()} {" "}
                    {/* each, max 20kg */}
                    {t("luggageCount")}
                    </span>
                </p>
            </Col>
            <Col md={4} className='common-count-btn d-flex align-items-center justify-content-end'>
                <Button onClick={() => { decreaseBagageCount() }}> - </Button>
                <span className='count-bag'>{passenger.bagageCount}</span>
                <Button onClick={() => { increaseBagageCount() }}> + </Button>
            </Col>
        </Row>
        </>
    )}


    </>)
}
export default PassengerForm;
