import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

import SliderVideo from '../../assets/video/comp_1.mp4'
import SliderVideoMobile from '../../assets/video/mob_1.mp4'
import main_slider from '../../assets/img/main_slider.jpg'
// Import Swiper styles
import 'swiper/css';
import './sliderMain.css';
import {useEffect, useRef, useState} from "react";

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}

const SliderMain = () => {
    const size = useWindowSize();
    const videoRef = useRef();
    const [videoSrc, setVideoSrc] = useState(SliderVideo);
    useEffect(() => {
            if(size.width <= 576) {
                console.log('mobVideo')
                setVideoSrc(SliderVideoMobile)
            } else  {
                console.log('deskTopVideo')
                setVideoSrc(SliderVideo)
            }
    }, [size.width]);
    useEffect(() => {
        videoRef.current?.load();
    }, [videoSrc]);
    return (
        <>
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper custom-video-block">
                <SwiperSlide>
                    {/*<img src={main_slider}/>*/}
                    <video ref={videoRef} width='100%' muted autoPlay loop  >/
                        <source src={videoSrc} type="video/mp4" />
                        Sorry, your browser doesn't support videos.
                    </video>
                </SwiperSlide>
            </Swiper>
        </>
    )
}
export default SliderMain;
