import React, { useEffect, useState } from 'react'
import SliderMain from '../slider/SliderMain'
import "./resendticket.css"
import busstop from "../../assets/img/bus_stop_image.png"
import bus from "../../assets/img/bus_image.png"
import share from "../../assets/img/share.svg"
import download from "../../assets/img/download.svg"
import pdffile from "../../assets/img/pdf_file.svg"
import { Image, Container } from 'react-bootstrap';
import { Button, Input } from "antd";
import apiService from '../../services/api.service';
import moment from 'moment';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ResendTicket = () => {
  const [check, setCheck] = useState(true)
  const [state, setState] = useState(false)
  const [email, setEmail] = useState("")
  const [tickets, setTickets] = useState([])
  const { t } = useTranslation()
  useEffect(() => {
    function checkEmail(obj) {
      if (obj) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(obj)) setCheck(true)
        else setCheck(false)
      }
    }
    checkEmail(email)
  }, [email])


  async function resendTicketFn(e) {
    e.preventDefault();
    if (check) {
      try {
        const res = await apiService.resendTicket({ email: email });
        setTickets(res.data.tickets)
        setState(false)
      } catch (error) {
        setState(true)
      }
    } else {
      setState(true)
    }
  }
  const onButtonClick = (id) => {
    fetch(`https://buslines-api.mindscape.xyz/uploads/tickets/ticket/${id}/ticket.pdf`).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'ticket.pdf';
        alink.click();
      })
    })
  }
  return (
    <>
      <SliderMain />
      <Container className='resend-main'>
        <div className='resend-ticket-info'>
          <div className='resend-ticket-info-wrapper'>
            <h3>
              {t("resendTicket")}
              {/* Resend ticket info */}
            </h3>
            <p>
              {t("resendDetails")}
              {/* View your booking details by entering the fields below.
              You will find your booking number in your
              confirmation e-mail or on your ticket. */}
            </p>
            <div className='resend-image-container'>
              <Image alt='bus' src={bus} className='resend-image-bus' />
              <Image alt='busstop' src={busstop} className='resend-image' />
            </div>

            <div className='resend-email-container'>
              <div className='resend-email-form'>
                <label htmlFor='email'>Email</label>
                <Input value={email} onChange={(e) => setEmail(e.target.value)} id='email' type='text' name='e-mail' className={`resend-email-input ${(!check || state) && "border-alert"}`} />
                {!check && <div className='email-alert'>
                  {t("emailInputError")}
                  {/* Please, input your email correctly! */}
                </div>}
                {check && state && <div className='email-alert'>
                  {t("noTicketsOnEmail")}
                  {/* Sorry, there are no tickets reserved for this email address! */}
                </div>}
              </div>
              <Button onClick={resendTicketFn} className='resend-btn'>
                {t("preview")}
                {/* Preview Tickets */}
              </Button>
            </div>

            {tickets && tickets.map((ticket) => <div key={ticket.id} className='resend-ticket-booking-info'>
              <div className='resend-ticket-booking-number'>
                <div>
                  <h5>Booking number:</h5>
                  <h4>#{ticket.code}</h4>
                </div>
                <Link to={`https://buslines-api.mindscape.xyz/uploads/tickets/ticket/${ticket.id}/ticket.pdf`} target='_blank' rel='noopener noreferrer'>
                  <img alt='pdffile' src={pdffile} width={28} />
                </Link>
              </div>
              <div className='resend-ticket-booking-details'>
                <div className='departure'>
                  <h5>Booking details:</h5>
                  <h6>{moment(ticket.departureTrip.departureDate).format("HH:mm | DD MMM YYYY")}</h6>
                  <h4>{ticket.departureTrip.station.departure.address?.split(",")[0]}</h4>
                  <h6>{ticket.departureTrip.station.departure.country.name}</h6></div>
                <div className='arrival'>
                  <h5>empty</h5>
                  <div className='icons-container'>
                    <h6>{moment(ticket.arrivalTrip.departureDate).format("HH:mm | DD MMM YYYY")}</h6>
                    <div className='icons'><button className='bland-btn' onClick={() => onButtonClick(ticket.id)} >
                      <img alt='download' src={download} width={20} />
                    </button>
                      <button className='bland-btn'>
                        <img alt='share' src={share} width={20} />
                      </button>
                    </div>
                  </div>
                  <h4>{ticket.arrivalTrip.station.departure.address?.split(",")[0]}</h4>
                  <h6>{ticket.arrivalTrip.station.departure.country.name}</h6></div>
              </div>

            </div>
            )}
          </div>

        </div>
      </Container></>
  )
}

export default ResendTicket
