import {useTranslation} from "react-i18next";

import {Col, Container, Image, Row} from "react-bootstrap";

const PrivacyPage = () => {

    const { t } = useTranslation()

    return (<>
        <Container>

            <h2>Datenschutz</h2>
            <p>Vorwort</p>
            <p>Wir, die Flix SE samt unserer Tochtergesellschaften (nachfolgend gemeinsam: "FlixBus", "wir" oder "uns") m&ouml;chten Sie an dieser Stelle &uuml;ber den Datenschutz bei FlixBus informieren.</p>
            <p>Datenschutzrechtliche Vorschriften zum Schutz der von einer Verarbeitung betroffenen Person (wir sprechen Sie als betroffene Person nachfolgend auch mit "Kunde", "Nutzer", "Sie", "Ihnen" oder "Betroffener" an) ergeben sich insbesondere aus der EU-Datenschutz-Grundverordnung (Verordnung (EU) 2016/679; nachfolgend: "DSGVO"). Soweit wir entweder alleine oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Datenverarbeitung entscheiden, umfasst dies vor allem die Pflicht, Sie transparent &uuml;ber Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung zu informieren (vgl. Art. 13 und 14 DSGVO).</p>
            <p>Mit dieser Erkl&auml;rung (nachfolgend: "Datenschutzerkl&auml;rung") informieren wir Sie dar&uuml;ber, in welcher Weise Ihre personenbezogenen Daten von uns verarbeitet werden.</p>
            <p>Unsere Datenschutzerkl&auml;rung ist modular aufgebaut. Sie besteht aus einem Teil mit allgemeinen Informationen f&uuml;r jegliche Verarbeitung personenbezogener Daten und Verarbeitungssituationen, die bei jedem Aufruf unserer Webseite zum Tragen kommen (Ziffer 1 Allgemeine Informationen) und einem besonderen Teil, dessen Inhalt sich jeweils nur auf die dort angegebene Verarbeitungssituation mit Bezeichnung des jeweiligen Angebots oder Produkts bezieht, insbesondere den hier n&auml;her ausgestalteten Besuch von Webseiten (Ziffer 2 Besondere Informationen).</p>
            <p>Weitere rechtliche Informationen finden Sie hier:</p>
            <p>Bez&uuml;glich FlixBus:</p>
            <p>Allgemeinen Gesch&auml;fts- und Buchungsbedingungen (AGB)</p>
            <p>Allgemeine Bef&ouml;rderungsbedingungen (ABB)</p>
            <p>Bez&uuml;glich FlixTrain:</p>
            <p>Tarifbestimmungen der FlixTrain GmbH</p>
            <p>Allgemeine Gesch&auml;fts- und Bef&ouml;rderungsbedingungen der FlixTrain GmbH (AGB)</p>
            <p>Datenschutzerkl&auml;rung der FlixTrain GmbH f&uuml;r FlixTrain in Deutschland</p>
            <p>F&uuml;r Bewerber:</p>
            <p>Datenschutzhinweise f&uuml;r Bewerber</p>
            <p>1. Allgemeine Informationen</p>
            <p>1.1 Begriffsbestimmungen</p>
            <p>Dieser Datenschutzerkl&auml;rung liegen die folgenden, an Art. 4 DSGVO orientierten, Begriffsbestimmungen zugrunde:</p>
            <p>"Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte oder identifizierbare nat&uuml;rliche Person (&bdquo;betroffene Person&ldquo;) beziehen. Identifizierbar ist eine Person, wenn sie direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, einer Kennnummer, einer Online-Kennung, Standortdaten oder mithilfe von Informationen zu ihren physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identit&auml;tsmerkmalen identifiziert werden kann (Art. 4 Nr. 1 DSGVO). Die Identifizierbarkeit kann auch mittels einer Verkn&uuml;pfung von derartigen Informationen oder anderem Zusatzwissen gegeben sein. Auf das Zustandekommen, die Form oder die Verk&ouml;rperung der Informationen kommt es nicht an (auch Fotos, Video- oder Tonaufnahmen k&ouml;nnen personenbezogene Daten enthalten).</p>
            <p>"Verarbeitung" ist jeder Vorgang, bei dem mit personenbezogenen Daten umgegangen wird, gleich ob mit oder ohne Hilfe automatisierter (d.h. technikgest&uuml;tzter) Verfahren. Dies umfasst insbesondere das Erheben (d.h. die Beschaffung), das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Ver&auml;nderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch &Uuml;bermittlung, die Verbreitung oder sonstige Bereitstellung, den Abgleich, die Verkn&uuml;pfung, die Einschr&auml;nkung, das L&ouml;schen oder die Vernichtung von personenbezogenen Daten sowie die &Auml;nderung einer Ziel- oder Zweckbestimmung, die einer Datenverarbeitung urspr&uuml;nglich zugrunde gelegt wurde (Art. 4 Nr. 2 DSGVO).</p>
            <p>"Verantwortlicher" ist die nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet (Art. 4 Nr. 7 DSGVO).</p>
            <p>"Auftragsverarbeiter" ist eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen, insbesondere gem&auml;&szlig; dessen Weisungen, verarbeitet (Art. 4 Nr. 8 DSGVO).</p>
            <p>"Dritter" ist jede nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle au&szlig;er dem Betroffenen, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten; dazu geh&ouml;ren auch andere konzernangeh&ouml;rige juristische Personen (Art. 4 Nr. 10 DSGVO).</p>
            <p>"Einwilligung" der betroffenen Person bezeichnet jede freiwillig f&uuml;r den bestimmten Fall, in informierter Weise und unmissverst&auml;ndlich abgegebene Willensbekundung in Form einer Erkl&auml;rung oder einer sonstigen eindeutigen best&auml;tigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist (Art. 4 Nr. 11 DSGVO).</p>
            <p>1.2 Name und Anschrift des f&uuml;r die Verarbeitung Verantwortlichen</p>
            <p>Der f&uuml;r die Verarbeitung Ihrer personenbezogenen Daten Verantwortliche (Art. 4 Nr. 7 DSGVO) ist:</p>
            <p>Flix SE</p>
            <p>Friedenheimer Br&uuml;cke 16</p>
            <p>80639 M&uuml;nchen</p>
            <p>Telefon: +49 (0)30 300 137 300</p>
            <p>E-Mail: service@flixbus.de</p>
            <p>Weitere Angaben zu unserem Unternehmen entnehmen Sie bitte dem Impressum.</p>
            <p>1.3 Kontaktdaten des Datenschutzbeauftragten</p>
            <p>Bei allen Fragen und als Ansprechpartner zum Thema Datenschutz bei uns steht Ihnen unser betrieblicher Datenschutzbeauftragter jederzeit zur Verf&uuml;gung.</p>
            <p>Kontaktdaten:</p>
            <p>Flix SE</p>
            <p>Friedenheimer Br&uuml;cke 16</p>
            <p>80639 M&uuml;nchen</p>
            <p>E-Mail: data.protection@flixbus.com</p>
            <p>F&uuml;r allgemeine Fragen zu FlixBus wenden Sie sich bitte gerne an service@flixbus.de.</p>
            <p>1.4 Rechtsgrundlagen der Datenverarbeitung</p>
            <p>Eine Verarbeitung personenbezogener Daten ist erlaubt, wenn mindestens eine der folgenden Rechtsgrundlagen vorliegt:</p>
            <p>Art. 6 Abs. 1 lit. a DSGVO: Wenn die betroffene Person ihre Einwilligung zu der Verarbeitung der sie betreffenden personenbezogenen Daten f&uuml;r einen oder mehrere bestimmte Zwecke gegeben hat;</p>
            <p>Art. 6 Abs. 1 lit. b DSGVO: Wenn die Verarbeitung f&uuml;r die Erf&uuml;llung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist, die auf Anfrage der betroffenen Person erfolgen;</p>
            <p>Art. 6 Abs. 1 lit. c DSGVO: Wenn die Verarbeitung zur Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich ist, der der Verantwortliche unterliegt (z. B. eine gesetzliche Aufbewahrungspflicht);</p>
            <p>Art. 6 Abs. 1 lit. d DSGVO: Wenn die Verarbeitung erforderlich ist, um lebenswichtige Interessen der betroffenen Person oder einer anderen nat&uuml;rlichen Person zu sch&uuml;tzen;</p>
            <p>Art. 6 Abs. 1 lit. e DSGVO: Wenn die Verarbeitung f&uuml;r die Wahrnehmung einer Aufgabe erforderlich ist, die im &ouml;ffentlichen Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die dem Verantwortlichen &uuml;bertragen wurde oder</p>
            <p>Art. 6 Abs. 1 lit. f DSGVO: Wenn die Verarbeitung zur Wahrung berechtigter Interessen des Verantwortlichen oder eines Dritten erforderlich ist, sofern nicht die gegenl&auml;ufigen Interessen oder Rechte der betroffenen Person &uuml;berwiegen (insbesondere dann, wenn es sich dabei um einen Minderj&auml;hrigen handelt).</p>
            <p>F&uuml;r von uns vorgenommenen Verarbeitungen geben wir unter Ziffer 2 jeweils eine anwendbare Rechtsgrundlage an. Eine Verarbeitung kann auch auf mehreren Rechtsgrundlagen beruhen.</p>
            <p>1.5 Kategorien von Empf&auml;ngern</p>
            <p>Unter bestimmten Voraussetzungen geben wir personenbezogene Daten von Ihnen an unsere Tochtergesellschaften weiter oder es werden personenbezogene Daten von unseren Tochtergesellschaften an uns weitergegeben, soweit dies zul&auml;ssig ist.</p>
            <p>Wie bei jedem gr&ouml;&szlig;eren Unternehmen, setzen auch wir dar&uuml;ber hinaus zur Abwicklung unseres Gesch&auml;ftsverkehrs auch externe in- und ausl&auml;ndische Dienstleister ein und arbeiten mit Partnerunternehmen im In- und Ausland zusammen. Hierzu z&auml;hlen etwa:</p>
            <p>Bef&ouml;rderer (eine &Uuml;bersicht der aktuellen Bef&ouml;rderer finden Sie hier)</p>
            <p>(IT-)Dienstleister</p>
            <p>Kreditinstitute und Zahlungsdienstleister</p>
            <p>Vertriebspartner</p>
            <p>Kundenserviceanbieter (intern/extern)</p>
            <p>Shopbetreiber</p>
            <p>Sicherheitsunternehmen</p>
            <p>(Reise-)Versicherer</p>
            <p>Weitere f&uuml;r unseren Gesch&auml;ftsbetrieb eingesetzte Partner (z.B. Auditoren, Banken, Versicherungen, Rechtsanw&auml;lte, Aufsichtsbeh&ouml;rden, Beteiligte bei Unternehmensk&auml;ufen)</p>
            <p>Die Dienstleister und Partnerunternehmen m&uuml;ssen Garantien daf&uuml;r bieten, dass geeignete technische und organisatorische Ma&szlig;nahmen von Ihnen so durchgef&uuml;hrt werden, dass die Verarbeitung die rechtlichen Anforderungen erf&uuml;llt und die Rechte der betroffenen Personen gewahrt werden.</p>
            <p>&Ouml;ffentlichen Stellen und Institutionen (z. B. Polizei, Staatsanwaltschaft, Aufsichtsbeh&ouml;rden) geben wir personenbezogene Daten bei Vorliegen einer entsprechenden Verpflichtung/Berechtigung weiter.</p>
            <p>F&uuml;r von uns vorgenommenen Verarbeitungen geben wir unter Ziffer 2 jeweils Kategorien der Datenempf&auml;nger an.</p>
            <p>1.6 Voraussetzungen der Weitergabe von personenbezogenen Daten in Drittl&auml;nder</p>
            <p>Im Rahmen unserer Gesch&auml;ftsbeziehungen k&ouml;nnen Ihre personenbezogenen Daten an Dritte weitergegeben oder offengelegt werden, die sich auch au&szlig;erhalb des Europ&auml;ischen Wirtschaftsraums (EWR), also in Drittl&auml;ndern, befinden.</p>
            <p>&Uuml;ber jeweilige Einzelheiten der Weitergabe in Drittl&auml;nder im Zusammenhang mit den von uns vorgenommenen Verarbeitungen unterrichten wir Sie ggf. unter Ziffer 2 an den daf&uuml;r relevanten Stellen.</p>
            <p>Einigen Drittl&auml;ndern bescheinigt die Europ&auml;ische Kommission durch sog. Angemessenheitsbeschl&uuml;sse einen Datenschutz, der dem EWR-Standard vergleichbar ist (eine Liste dieser L&auml;nder sowie eine Kopie der Angemessenheitsbeschl&uuml;sse ist hier abrufbar: http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.html).</p>
            <p>In anderen Drittl&auml;ndern, in die ggf. personenbezogene Daten &uuml;bertragen werden, herrscht aber unter Umst&auml;nden wegen fehlender gesetzlicher Bestimmungen kein durchg&auml;ngig hohes Datenschutzniveau. Soweit dies der Fall ist, achten wir darauf, dass der Datenschutz ausreichend gew&auml;hrleistet ist.</p>
            <p>M&ouml;glich ist dies etwa &uuml;ber bindende Unternehmensvorschriften (sog. Binding Corporate Rules), Standard-Vertragsklauseln der Europ&auml;ischen Kommission zum Schutz personenbezogener Daten, Zertifikate und anerkannte Verhaltenskodizes.</p>
            <p>Soweit es f&uuml;r Ihre Buchung und die damit verbundene Durchf&uuml;hrung und Abwicklung von Bef&ouml;rderungsleistungen erforderlich ist, ist die &Uuml;bermittlung der hierzu notwendigen personenbezogenen Daten in Drittstaaten gem&auml;&szlig; Art. 49 Abs. 1 lit. b DSGVO erlaubt.</p>
            <p>Bitte wenden Sie sich an unsere Datenschutzbeauftragte, wenn Sie hierzu n&auml;here Informationen erhalten m&ouml;chten.</p>
            <p>1.7 Speicherdauer und Datenl&ouml;schung</p>
            <p>Die Speicherdauer der erhobenen personenbezogenen Daten ist von dem Zweck, zu dem wir die Daten verarbeiten, abh&auml;ngig. Die Speicherung erfolgt so lange diese f&uuml;r die Erreichung des verfolgten Zwecks erforderlich ist.</p>
            <p>F&uuml;r von uns vorgenommenen Verarbeitungen geben wir unter Ziffer 2 jeweils an, wie lange die Daten bei uns gespeichert werden. Soweit nachfolgend keine ausdr&uuml;ckliche Speicherdauer angegeben wird, werden Ihre personenbezogenen Daten gel&ouml;scht oder gesperrt, sobald der Zweck oder die Rechtsgrundlage f&uuml;r die Speicherung entf&auml;llt.</p>
            <p>Eine Speicherung kann jedoch &uuml;ber die angegebene Zeit hinaus im Falle einer (drohenden) Rechtsstreitigkeit mit Ihnen oder eines sonstigen rechtlichen Verfahrens erfolgen oder wenn die Speicherung durch gesetzliche Vorschriften, denen wir als Verantwortlicher unterliegen, vorgesehen ist. Wenn die durch die gesetzlichen Vorschriften vorgeschriebene Speicherfrist abl&auml;uft, erfolgt eine Sperrung oder L&ouml;schung der personenbezogenen Daten, es sei denn, dass eine weitere Speicherung durch uns erforderlich ist und daf&uuml;r eine Rechtsgrundlage besteht.</p>
            <p>1.8 Automatisierte Entscheidungsfindung (einschlie&szlig;lich Profiling)</p>
            <p>Wir haben nicht die Absicht, von Ihnen erhobene personenbezogene Daten f&uuml;r ein Verfahren zur automatisierten Entscheidungsfindung (einschlie&szlig;lich Profiling) zu verwenden. Sollten wir diese Verfahren einsetzen wollen, werden wir Sie hier&uuml;ber im Rahmen der gesetzlichen Bestimmungen gesondert informieren.</p>
            <p>1.9 Keine Verpflichtung zur Bereitstellung personenbezogener Daten</p>
            <p>Wir machen den Abschluss von Vertr&auml;gen mit uns nicht grunds&auml;tzlich davon abh&auml;ngig, dass Sie uns zuvor personenbezogene Daten bereitstellen. Es besteht grunds&auml;tzlich auch keine gesetzliche oder vertragliche Verpflichtung, uns Ihre personenbezogenen Daten zur Verf&uuml;gung zu stellen; es kann jedoch sein, dass wir bestimmte Angebote nur eingeschr&auml;nkt oder gar nicht erbringen k&ouml;nnen, wenn Sie die daf&uuml;r erforderlichen Daten nicht bereitstellen.</p>
            <p>1.10 Gesetzliche Verpflichtung zur &Uuml;bermittlung bestimmter Daten</p>
            <p>Wir k&ouml;nnen unter Umst&auml;nden einer besonderen gesetzlichen oder rechtlichen Verpflichtung unterliegen, personenbezogene Daten f&uuml;r Dritte, insbesondere &ouml;ffentlichen Stellen, bereitzustellen.</p>
            <p>1.11 Datensicherheit</p>
            <p>Wir bedienen uns geeigneter technischer und organisatorischer Ma&szlig;nahmen, um Ihre Daten unter Ber&uuml;cksichtigung des Stands der Technik, der Implementierungskosten und der Natur, des Umfangs, des Kontextes und des Zwecks der Verarbeitung sowie der bestehenden Risiken einer Datenpanne (inklusive von deren Wahrscheinlichkeit und Auswirkungen) f&uuml;r den Betroffenen gegen zuf&auml;llige oder vors&auml;tzliche Manipulationen, teilweisen oder vollst&auml;ndigen Verlust, Zerst&ouml;rung oder gegen den unbefugten Zugriff Dritter zu sch&uuml;tzen (z.B. TSL-Verschl&uuml;sselung f&uuml;r unsere Webseiten). Unsere Sicherheitsma&szlig;nahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</p>
            <p>N&auml;here Informationen hierzu erteilen wir Ihnen auf Anfrage gerne. Wenden Sie sich hierzu bitte an unsere Datenschutzbeauftragte oder unseren CISO (Chief Information Security Officer).</p>
            <p>Seine Kontaktdaten sind:</p>
            <p>Flix SE</p>
            <p>Friedenheimer Br&uuml;cke 16</p>
            <p>80639 M&uuml;nchen</p>
            <p>E-Mail: it-security@flixbus.com</p>
            <p>1.12 Ihre Rechte</p>
            <p>Ihre Rechte als betroffene Person bez&uuml;glich Ihrer personenbezogenen Daten k&ouml;nnen Sie uns gegen&uuml;ber insbesondere unter den unter Ziffer 1.2 angegebenen Kontaktdaten jederzeit geltend machen. Den betroffenen Personen stehen folgende Rechte aus der DSGVO zur Verf&uuml;gung:</p>
            <p>Recht auf Auskunft</p>
            <p>Sie k&ouml;nnen Auskunft gem. Art. 15 DSGVO &uuml;ber Ihre von uns verarbeiteten personenbezogenen Daten verlangen. In Ihrem Auskunftsantrag sollten Sie Ihr Anliegen pr&auml;zisieren, um uns das Zusammenstellen der erforderlichen Daten zu erleichtern. Auf Verlangen stellen wir Ihnen eine Kopie der Daten, die Gegenstand der Verarbeitung sind, zur Verf&uuml;gung. Bitte beachten Sie, dass Ihr Auskunftsrecht unter bestimmten Umst&auml;nden gem&auml;&szlig; den gesetzlichen Vorschriften eingeschr&auml;nkt sein kann.</p>
            <p>Recht auf Berichtigung</p>
            <p>Sollten die Sie betreffenden Angaben nicht (mehr) zutreffend sein, k&ouml;nnen Sie nach Art. 16 DSGVO eine Berichtigung verlangen. Sollten Ihre Daten unvollst&auml;ndig sein, k&ouml;nnen Sie eine Vervollst&auml;ndigung verlangen.</p>
            <p>Recht auf L&ouml;schung</p>
            <p>Sie k&ouml;nnen unter den Bedingungen des Art. 17 DSGVO die L&ouml;schung Ihrer personenbezogenen Daten verlangen. Ihr Anspruch auf L&ouml;schung h&auml;ngt u. a. davon ab, ob die Sie betreffenden Daten von uns zur Erf&uuml;llung unserer gesetzlichen Aufgaben noch ben&ouml;tigt werden.</p>
            <p>Recht auf Einschr&auml;nkung der Verarbeitung</p>
            <p>Sie haben im Rahmen der Vorgaben des Art. 18 DSGVO das Recht, eine Einschr&auml;nkung der Verarbeitung der Sie betreffenden Daten zu verlangen.</p>
            <p>Recht auf Daten&uuml;bertragbarkeit</p>
            <p>Sie haben im Rahmen der Vorgaben des Art. 20 DSGVO das Recht Ihre Daten, die Sie uns bereitgestellt haben in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten oder die &Uuml;bermittlung an einen anderen Verantwortlichen zu verlangen.</p>
            <p>Recht auf Widerspruch</p>
            <p>Sie haben nach Art. 21 Abs. 1 DSGVO das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit der Verarbeitung der Sie betreffenden Daten zu widersprechen. Der werblichen Ansprache k&ouml;nnen Sie nach Art. 21 Abs. 2 DSGVO jederzeit mit Wirkung f&uuml;r die Zukunft widersprechen (Werbewiderspruch bei Direktwerbung).</p>
            <p>Recht auf Beschwerde</p>
            <p>Wenn Sie der Auffassung sind, dass wir bei der Verarbeitung Ihrer Daten datenschutzrechtliche Vorschriften nicht beachtet haben, k&ouml;nnen Sie sich bei einer Datenschutz-Aufsichtsbeh&ouml;rde &uuml;ber die Verarbeitung Ihrer personenbezogenen Daten beschweren, etwa bei der f&uuml;r uns zust&auml;ndigen Datenschutz-Aufsichtsbeh&ouml;rde:</p>
            <p>Bayerisches Landesamt f&uuml;r Datenschutzaufsicht, Promenade 18, 91522 Ansbach</p>
            <p>Recht auf Widerruf einer Einwilligung</p>
            <p>Sie k&ouml;nnen eine Einwilligung zur Verarbeitung Ihrer Daten jederzeit f&uuml;r die Zukunft widerrufen. Dies gilt auch f&uuml;r Einwilligungserkl&auml;rungen, die vor der Geltung der DSGVO, also vor dem 25.05.2018, erteilt wurden.</p>
            <p>2. Besondere Informationen</p>
            <p>2.1 Besuch unserer Webseiten</p>
            <p>Informationen zu FlixBus und den von uns angebotenen Leistungen erhalten Sie insbesondere unter www.flixbus.de / www.flixtrain.de samt den dazugeh&ouml;rigen Unterseiten (nachfolgend gemeinsam auch "Website&ldquo; oder "Webseiten"). Bei einem Besuch unserer Webseiten werden personenbezogene Daten von Ihnen verarbeitet.</p>
            <p>2.1.1 Bereitstellung der Webseiten</p>
            <p>Bei der informatorischen Nutzung der Webseiten werden die folgenden Kategorien personenbezogener Daten von uns erhoben, gespeichert und weiterverarbeitet:</p>
            <p>Protokolldaten: Wenn Sie unsere Webseiten besuchen, wird auf unserem Webserver ein sogenannter Protokolldatensatz (sog. Server-Logfiles) gespeichert.</p>
            <p>Dieser besteht aus:</p>
            <p>der Seite, von der aus die Seite angefordert wurde (sog. Referrer-URL)</p>
            <p>dem Namen und URL der angeforderten Seite</p>
            <p>dem Datum und der Uhrzeit des Aufrufs (in der Zeitzone des Servers)</p>
            <p>der Version des verwendeten Webbrowsers</p>
            <p>der IP-Adresse des anfragenden Rechners</p>
            <p>der &uuml;bertragenen Datenmenge</p>
            <p>dem Betriebssystem</p>
            <p>der Meldung, ob der Aufruf erfolgreich war (Zugriffsstatus/Http-Statuscode)</p>
            <p>der GMT-Zeitzonendifferenz</p>
            <p>F&uuml;r das Hosting unserer Webseiten sowie f&uuml;r statistische Auswertungen der Protokolldaten setzen wir IT-Dienstleister ein.</p>
            <p>Die Verarbeitung der Protokolldaten dient statistischen Zwecken und der Verbesserung der Qualit&auml;t unserer Webseiten, insbesondere der Stabilit&auml;t und der Sicherheit der Verbindung.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes Interesse besteht darin, Ihnen die Webseiten ordnungsgem&auml;&szlig; zur Verf&uuml;gung stellen zu k&ouml;nnen.</p>
            <p>2.1.2 Kontaktformulare</p>
            <p>Bei Nutzung von Kontaktformularen werden die dadurch &uuml;bermittelten Daten verarbeitet (z. B. Anrede, Name und Vorname, Anschrift, Firma, E-Mail-Adresse und der Zeitpunkt der &Uuml;bermittlung, Inhalte der Anfrage).</p>
            <p>Die Verarbeitung von Kontaktformulardaten erfolgt zur Bearbeitung von Anfragen, je nach Ausgangssituation und Inhalt Ihrer Anfrage entweder auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. b DSGVO, wenn es um eine vertragsbezogene Anfrage geht oder in den anderen F&auml;llen auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f DSGVO, unser berechtigtes Interesse besteht darin, Kontaktanfrage zu bearbeiten.</p>
            <p>F&uuml;r die Beantwortung von Kontaktanfragen &uuml;ber unsere Kontaktformulare setzen wir Kundenserviceanbieter im Wege der Auftragsverarbeitung ein.</p>
            <p>Dar&uuml;ber hinaus speichern wir die Kontaktformulardaten sowie die jeweilige IP-Adresse auch um Nachweispflichten nachzukommen, zur Sicherstellung der Einhaltung und Dokumentation gesetzlicher Anforderungen, um ggf. einen m&ouml;glichen Missbrauch Ihrer pers&ouml;nlichen Daten aufkl&auml;ren zu k&ouml;nnen und um die Sicherheit unserer Systeme zu gew&auml;hrleisten.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. c bzw. f DSGVO.</p>
            <p>2.1.3 Buchung, Durchf&uuml;hrung und Abwicklung von Bef&ouml;rderungsleistungen</p>
            <p>Bei der Buchung von Tickets f&uuml;r Bef&ouml;rderungsleistungen werden die folgenden Kategorien personenbezogener Daten von uns erhoben, gespeichert und weiterverarbeitet:</p>
            <p>E-Mail-Adresse</p>
            <p>Name und Vorname</p>
            <p>Verbindungsdaten</p>
            <p>Zahlungsdaten</p>
            <p>Geburtsdatum (bei Bef&ouml;rderungen zum Preis f&uuml;r Kinder)</p>
            <p>Zustimmung zu den jeweiligen Gesch&auml;ftsbedingungen</p>
            <p>Sitzplatzreservierungsinformationen</p>
            <p>Gep&auml;ckangaben</p>
            <p>Sprache der Buchungsdomain</p>
            <p>Buchungskanal (Web oder App)</p>
            <p>Dar&uuml;ber hinaus haben Sie die M&ouml;glichkeit, eine Telefonnummer zur Kontaktaufnahme bei Versp&auml;tungen oder &Auml;nderungen im Reiseverlauf anzugeben (optional).</p>
            <p>Die Verarbeitung dieser Daten erfolgt zur Buchung, Durchf&uuml;hrung und Abwicklung der Bef&ouml;rderungsleistungen einschlie&szlig;lich der Kundenbetreuung, sowie zur Erf&uuml;llung rechtlicher Verpflichtungen.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. b, c DSGVO.</p>
            <p>Einige dieser Daten nutzen wir dar&uuml;ber hinaus auch f&uuml;r Produktempfehlungen, siehe Ziffer 2.1.4 sowie f&uuml;r den Newsletter, siehe Ziffer 2.1.5 und das Kundenkonto, siehe Ziffer 2.1.6.</p>
            <p>Bei Buchungen von Tickets f&uuml;r internationale Bef&ouml;rderungsleistungen werden je nach Ort der Abfahrt und Ankunft dar&uuml;ber hinaus noch folgende Kategorien personenbezogener Daten erhoben:</p>
            <p>Angaben zum Geschlecht</p>
            <p>Ausweis-, Pass-, oder ID-Nummer</p>
            <p>Die Verarbeitung dieser Daten erfolgt zur Buchung, Durchf&uuml;hrung und Abwicklung der Bef&ouml;rderungsleistungen sowie zur Erf&uuml;llung rechtlicher Verpflichtungen nach der nationalen Gesetzgebung am Ort der Abfahrt und Ankunft.</p>
            <p>Wir geben die oben genannten Daten an den oder die jeweiligen Bef&ouml;rderer, sowie &ndash; bei Bestehen einer entsprechenden Verpflichtung/Berechtigung auch an &ouml;ffentliche Stellen weiter.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. b bzw. c DSGVO.</p>
            <p>F&uuml;r die sichere Abwicklung der von Ihnen veranlassten Zahlungen werden die erforderlichen Zahlungsdaten an einen Zahlungsdienstleister &uuml;bermittelt.</p>
            <p>Unser Zahlungsdienstleister sind:</p>
            <p>Zahlungsdienstleister Zahlungsarten</p>
            <p>Adyen N.V.</p>
            <p>Simon Carmiggeltstraat 6-50, 1011 DJ, Niederlande</p>
            <p>Datenschutzerkl&auml;rung:</p>
            <p>https://www.adyen.com/policies-and-disclaimer/privacy-policy </p>
            <p>Credit Card</p>
            <p>Swish</p>
            <p>Google Pay</p>
            <p>Apple Pay</p>
            <p>iDeal</p>
            <p>Dotpay</p>
            <p>Sofort/Klarna</p>
            <p>PayU Bilgi Teknolojileri A.S.</p>
            <p>Otakcilar Cad. No: 78, Flat Ofis D-Blok 34050, Eyup - ISTANBUL</p>
            <p>Datenschutzerkl&auml;rung:</p>
            <p>https://payu.in/privacy-policy Credit Card</p>
            <p>Paymill Plus GmbH</p>
            <p>St.-Martin-Stra&szlig;e 63, 81669 M&uuml;nchen</p>
            <p>Datenschutzerkl&auml;rung:</p>
            <p>https://www.paymill.com/de/datenschutz/ Postfinance</p>
            <p>PayPal (Europe) S.&agrave; r.l. et Cie, S.C.A.</p>
            <p>22-24 Boulevard Royal, 2449 Luxemburg</p>
            <p>Datenschutzerkl&auml;rung:</p>
            <p>https://www.paypal.com/us/webapps/mpp/ua/privacy-full PayPal</p>
            <p>Satispay Europe S.A.</p>
            <p>53 Boulevard Royal, 2449 Luxemburg</p>
            <p>Datenschutzerkl&auml;rung:</p>
            <p>https://www.satispay.com/de-lu/datenschutz-und-cookies/datenschutzerklarung/ Satispay</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. b bzw. f DSGVO.</p>
            <p>F&uuml;r bestimmte Buchungen nutzen wir im Wege der Auftragsverarbeitung auch Technologien und Dienste der Distribusion Technologies GmbH (Wattstrasse 10, 13355 Berlin, Telefon: +49-30-3465507-50, E-Mail: dataprotection@distribusion.com).</p>
            <p>2.1.4 Produktempfehlung</p>
            <p>Soweit dies erlaubt ist, k&ouml;nnen wir die im Zusammenhang mit der Buchung oder Bef&ouml;rderungsleistung erhaltene E-Mail-Adresse nutzen, um Ihnen regelm&auml;&szlig;ig Angebote zu &auml;hnlichen Produkten, wie den bereits gekauften, aus unserem Sortiment per E-Mail zuzusenden.</p>
            <p>F&uuml;r die Versendung von Produktempfehlungen setzen wir externe Kundenserviceanbieter im Wege der Auftragsverarbeiter ein.</p>
            <p>Diese Produktempfehlungen erhalten Sie von uns unabh&auml;ngig davon, ob Sie einen Newsletter abonniert haben oder ob Sie in die Marketing-Kommunikation per E-Mail eingewilligt haben. Wir wollen Ihnen auf diese Weise Informationen &uuml;ber Produkte aus unserem Angebot zukommen lassen, die Sie auf Grundlage Ihrer letzten Eink&auml;ufe bei uns interessieren k&ouml;nnten.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO, unser berechtigtes Interesse liegt darin, &uuml;ber unser Angebot zu informieren und Sie auf bestimmte Produkte hinzuweisen.</p>
            <p>Der Verwendung Ihrer E-Mail-Adresse zu diesem Zwecke k&ouml;nnen Sie jederzeit &uuml;ber den Abmeldelink der Produktempfehlung oder durch eine Nachricht an unsubscribe@flixbus.com widersprechen.</p>
            <p>2.1.5 Newsletter</p>
            <p>Falls Sie sich zus&auml;tzlich &uuml;ber unseren Anmeldelink f&uuml;r den Newsletter registrieren, bitten wir Sie um Ihre Einwilligung zur Verarbeitung Ihrer Daten (E-Mail-Adresse, Vor- und Nachname, Wohnort), um Ihnen regelm&auml;&szlig;ig unseren Newsletter per E-Mail zuzusenden.</p>
            <p>Im Rahmen Ihrer Anmeldung f&uuml;r den Newsletter holen wir dar&uuml;ber hinaus Ihre Einwilligung ein, dass wir den Inhalt unseres Newsletters entsprechend Ihren Bed&uuml;rfnissen und Interessen personalisieren d&uuml;rfen.</p>
            <p>F&uuml;r die Anmeldung zu unserem Newsletter verwenden wir das sog. Double-opt-in-Verfahren. Das hei&szlig;t, dass wir Ihnen nach Ihrer Anmeldung eine E-Mail an die angegebene E-Mail-Adresse senden, in welcher wir Sie um Best&auml;tigung bitten, dass Sie den Versand des Newsletters w&uuml;nschen. Wenn Sie Ihre Anmeldung nicht innerhalb von 24 Stunden best&auml;tigen, werden Ihre Informationen gesperrt und nach einem Monat automatisch gel&ouml;scht.</p>
            <p>Der Newsletter kann alle Waren, Dienstleistungen, Produkte, Angebote und Aktionen betreffen, die durch den Verantwortlichen (Ziffer 1.2) und/oder mit dem Verantwortlichen verbundene Unternehmen selbst sowie von Partnerunternehmen angeboten werden.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. a DSGVO.</p>
            <p>Dar&uuml;ber hinaus speichern wir jeweils Ihre eingesetzten IP-Adressen und Zeitpunkte der Anmeldung und Best&auml;tigung. Zweck des Verfahrens ist, Ihre Anmeldung nachweisen und ggf. einen m&ouml;glichen Missbrauch Ihrer pers&ouml;nlichen Daten aufkl&auml;ren zu k&ouml;nnen Soweit wir hierzu personenbezogene Daten von Ihnen verarbeitet, erfolgt dies auf der Grundlage unserer berechtigten Interessen an der Sicherstellung der Einhaltung und Dokumentation gesetzlicher Anforderungen.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO, unser berechtigtes Interesse liegt darin, die Einwilligung nachweisen zu k&ouml;nnen.</p>
            <p>Die Verwendung Ihrer E-Mail-Adresse k&ouml;nnen Sie jederzeit &uuml;ber den Abmeldelink des Newsletters oder durch eine Nachricht an unsubscribe@flixbus.com widerrufen. Die Rechtm&auml;&szlig;igkeit der bereits erfolgten Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.</p>
            <p>F&uuml;r die Versendung des Newsletters setzen wir externe IT-Dienstleister im Wege der Auftragsverarbeiter ein.</p>
            <p>2.1.6 Kundenkonto</p>
            <p>Sie haben bei uns die M&ouml;glichkeit ein pers&ouml;nliches Kundenkonto anzulegen. Im passwortgesch&uuml;tzten Bereich des Kundenkontos k&ouml;nnen Sie bequem Ihre Buchungen verwalten und f&uuml;r zuk&uuml;nftige Fahrten Ihre Daten hinterlegen lassen.</p>
            <p>Um ein Kundenkonto anzulegen werden folgende Pflichtangaben erhoben:</p>
            <p>E-Mail-Adresse</p>
            <p>Vor- und Nachname</p>
            <p>Passwort (selbst gew&auml;hlt)</p>
            <p>Diese Daten werden f&uuml;r die Verwaltung Ihres Kundenkontos sowie zur Rechnungserstellung verwendet.</p>
            <p>Zus&auml;tzlich k&ouml;nnen Sie in Ihrem Kundenkonto auch eine Handynummer zur Kontaktaufnahme im Falle einer Versp&auml;tung oder &Auml;nderung des Reiseverlaufs hinterlegen (optional).</p>
            <p>Dar&uuml;ber hinaus haben Sie die M&ouml;glichkeit in Ihrem Kundenkonto folgende weiteren Daten zu hinterlegen (optional):</p>
            <p>Anrede</p>
            <p>Geburtsdatum</p>
            <p>Adresse (Postleitzahl, Stadt und Land)</p>
            <p>Zahlungsmethoden</p>
            <p>Diese Daten werden f&uuml;r die Verwaltung Ihres Kundenkontos sowie zur Rechnungserstellung verwendet und k&ouml;nnen dar&uuml;ber hinaus f&uuml;r die Versendung personalisierter Produktempfehlungen (Ziffer 2.1.4) und &ndash; wenn Sie sich hierzu angemeldet haben &ndash; Newsletter (Ziffer 2.1.5) verwendet werden.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. a, b bzw. f DSGVO.</p>
            <p>Sofern Sie hierin eingewilligt haben, werden mit der Funktion &bdquo;Angemeldet bleiben&ldquo; sog. persistente Cookies auf ihrem Endger&auml;t abgelegt, welche dazu dienen, dass Sie sich bei darauffolgenden Besuchen auf unserer Website nicht erneut anmelden m&uuml;ssen. Diese Funktion steht Ihnen nicht zur Verf&uuml;gung, wenn Sie in den Einstellungen Ihres Browsers die Speicherung von solchen Cookies deaktiviert haben.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO.</p>
            <p>Sie k&ouml;nnen das Kundenkonto &ndash; und damit auch Ihre hinterlegten personenbezogenen Daten &ndash; jederzeit in Ihrem pers&ouml;nlichen Kundenkonto aktualisieren oder l&ouml;schen.</p>
            <p>2.1.7 Einsatz von Cookies, Plugins und sonstiger Dienste</p>
            <p>Beim Besuch unserer Website werden Informationen in Form eines Cookies (kleine Textdateien) auf Ihrem Endger&auml;t gespeichert. Darin werden Angaben &uuml;ber Ihre Nutzung der Website gespeichert (Identifikations-ID, Besuchsdatum, etc.). Mit dem Einsatz von Cookies erleichtern wir Ihnen die Nutzung unseres Online-Angebots durch verschiedene Service-Funktionen (wie z.B. die Wiedererkennung von vorangegangenen Besuchen) und k&ouml;nnen damit das Internetangebot besser auf Ihre Bed&uuml;rfnisse abstimmen.</p>
            <p>Rechtsgrundlage f&uuml;r den Einsatz technisch notwendiger Cookies ist Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes Interesse besteht darin, Ihnen unsere Webseiten mit den spezifischen Funktionalit&auml;ten zur Verf&uuml;gung zu stellen, zu verbessern sowie f&uuml;r die Sicherheit und Integrit&auml;t unserer Webseiten zu sorgen.</p>
            <p>Sie k&ouml;nnen das Speichern von Cookies unterbinden und bereits vorhandene Cookies l&ouml;schen, indem Sie in Ihrem Browser die entsprechenden Einstellungen vornehmen. Die Hilfefunktion der meisten Browser erkl&auml;rt Ihnen, wie Sie diese Einstellungen vornehmen k&ouml;nnen. Wenn Sie Cookies nicht akzeptieren, kann dies jedoch die Service-Funktionen des Internetangebots beeintr&auml;chtigen. Wir empfehlen daher die Cookie-Funktion eingeschaltet zu lassen.</p>
            <p>Umfassende Informationen dazu, wie man dies auf einer Vielzahl von Browsern bewerkstelligen kann, erhalten Sie auf den folgenden Internetseiten: youronlinechoices, Network Advertising Initiative und/oder Digital Advertising Alliance. Sie finden dort auch Angaben dazu, wie Sie Cookies von Ihrem Computer l&ouml;schen k&ouml;nnen sowie allgemeine Informationen &uuml;ber Cookies. Wir nutzen verschiedene Arten von Cookies:</p>
            <p>Bei transienten Cookies, die auch als tempor&auml;re oder &bdquo;Session-Cookies&ldquo; bezeichnet werden, handelt es sich um Cookies, die gel&ouml;scht werden, nachdem Sie unser Onlineangebot verlassen und den Browser schlie&szlig;en. In derartigen Cookies werden z. B. Spracheinstellungen oder der Inhalt der Bestellung gespeichert.</p>
            <p>Persistente oder permanente Cookies, bleiben auch nach dem Schlie&szlig;en des Browsers gespeichert. So k&ouml;nnen z. B. der Login-Status oder eingegebene Suchbegriffe gespeichert werden. Solche Cookies nutzen wir unter anderem f&uuml;r Reichweitenmessung oder Marketingzwecke. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gel&ouml;scht, die sich je nach Cookie unterscheiden kann. Sie k&ouml;nnen diese Cookies jedoch in den Sicherheitseinstellungen Ihres Browsers jederzeit l&ouml;schen.</p>
            <p>Neben sogenannten &bdquo;First-Party-Cookies&ldquo;, die von uns als f&uuml;r die Datenverarbeitung Verantwortlichen gesetzt werden, kommen auch &bdquo;Third-Party-Cookies&ldquo; zum Einsatz, die von anderen Anbietern angeboten werden.</p>
            <p>Wir bieten Ihnen im Rahmen des Consent-Managements (Consent Banner) die M&ouml;glichkeit, nach Ihren Vorgaben &uuml;ber das Setzen von Cookies und den Einsatz vergleichbarer Technologien zu entscheiden. Eine detaillierte &Uuml;bersicht mit umfassenden Informationen zu den genutzten Diensten sowie Zugriff auf Ihre Consent-Einstellungen einschl. der M&ouml;glichkeit des Widerrufs erhalten Sie hier.</p>
            <p>2.1.8 Maschinelles Lernen und dynamische Anpassung der Website/App</p>
            <p>Wir nutzen maschinelles Lernen zur Optimierung und Automatisierung unserer Prozesse. Dazu geh&ouml;rt das Trainieren einer systemgest&uuml;tzten Entscheidungslogik basierend auf Anwendungsf&auml;llen, d. h. das System kann aus der Vergangenheit lernen und Algorithmen verwenden, um statistische Modelle zu entwickeln, die in Zukunft auf &auml;hnliche Aufgaben angewendet werden k&ouml;nnen.</p>
            <p>Wir verwenden beispielsweise maschinelles Lernen in einem gesicherten und gehashten Modell, um schnell und unkompliziert individuelle Reiseangebote f&uuml;r Sie zu erstellen. Hierzu verwenden wir Rahmendaten, insbesondere die Benutzer-ID, den entsprechenden Reise- und Suchverlauf sowie die IP-Adresse.</p>
            <p>Dar&uuml;ber hinaus verwenden wir Daten zur automatischen Anpassung unserer Website und App, um Ihnen die bestm&ouml;gliche Erfahrung zu bieten. So k&ouml;nnen wir zum Beispiel Ihren Kaufverlauf nutzen, um Ihnen zus&auml;tzliche Dienstleistungen anzubieten (z. B. das Hinzuf&uuml;gen einer Sitzplatzreservierung, die Kaufoption f&uuml;r die Mitf&uuml;hrung von Zusatzgep&auml;ck) oder Sie bei Ihrem Benutzererlebnis zu unterst&uuml;tzen (z. B. wenn Sie kurz vor einer Reise stehen, k&ouml;nnten wir Sie auf die Echtzeitverfolgung Ihres Busses hinweisen).</p>
            <p>Hierzu nutzen wir neben den oben aufgef&uuml;hrten Rahmendaten auch den Kaufverlauf und Kontakte mit Flix &uuml;ber den Kundendienst. Die Daten werden durch &bdquo;Salting&ldquo; zus&auml;tzlich gesichert und gehasht, um eine zus&auml;tzliche Schutzebene bereitzustellen. </p>
            <p>Diese Verarbeitung st&uuml;tzt sich auf unser berechtigtes Interesse gem&auml;&szlig; Art. 6 Abs. 1 (f) DSGVO, da wir ein wirtschaftliches Interesse daran haben, Ihnen die bestm&ouml;gliche Erfahrungen anzubieten. Auch Sie profitieren davon, da Sie statt einer Standardempfehlung ein Reiseangebot erhalten, das auf Ihre Bed&uuml;rfnisse zugeschnitten ist, oder wir machen Sie auf Dienstleistungen aufmerksam, die Ihre Planung, Reise und Ihre Erfahrung nach der Reise optimieren.</p>
            <p>Wenn Sie der Verarbeitung Ihrer Daten zum Zweck des maschinellen Lernens oder der dynamischen Anpassung der Website/App widersprechen m&ouml;chten, k&ouml;nnen Sie dies gem&auml;&szlig; den gesetzlichen Bestimmungen jederzeit tun, indem Sie Ihren Widerspruch per E-Mail an data.protection@flixbus.com senden. Bitte beachten Sie, dass Sie in diesem Fall nur noch Standarddienstleistungen erhalten.</p>
            <p>2.1.9 Betrugspr&auml;vention</p>
            <p>Um betr&uuml;gerische Buchungen zu verhindern, verarbeiten wir auftragsbezogene Daten wie z. B. IP-Adresse, Name, E-Mail-Adresse.</p>
            <p>Dies ist gem&auml;&szlig; Art. 6 Abs. 1 lit. (f) DSGVO rechtm&auml;&szlig;ig. Unser berechtigtes Interesse besteht darin, zu verhindern, dass wir Opfer von Betrug werden und finanzielle Verluste erleiden.</p>
            <p>Im Einzelfall kann nach der Buchung eine Entscheidung &uuml;ber eine Stornierung auf der Grundlage einer automatisierten Entscheidung erfolgen. Die Logik hierf&uuml;r basiert auf einer Reihe interner Algorithmen, die relevante Datenpunkte verarbeiten und uns Bewertungen zu &Uuml;bereinstimmungen mit verschiedenen betr&uuml;gerischen Mustern liefern oder die Datenpunkte mit Schwellenwerten und Werten vergleichen, welche f&uuml;r betr&uuml;gerische Muster typisch sind, um betr&uuml;gerische Buchungen zu erkennen. Wenn Sie diese Entscheidung anfechten, Ihren eigenen Standpunkt darlegen oder das Eingreifen einer Person auf Seiten des Verantwortlichen erwirken m&ouml;chten, wenden Sie sich bitte an data.protection@flixbus.com.</p>
            <p>2.2 Kundenservice</p>
            <p>Wenn Sie mit unserem Kundenservice in Kontakt treten, erheben wir die personenbezogenen Daten, die Sie uns von sich aus mitteilen. Diese k&ouml;nnen Sie beispielsweise per E-Mail, Telefon oder per Brief an uns senden. Ihre personenbezogenen Daten werden nur zum Kontakt oder zu dem Zweck verwendet, f&uuml;r den Sie uns diese Daten bereitgestellt haben, z.B. zur Bearbeitung Ihrer Anfragen, technische Administration oder Kundenverwaltung.</p>
            <p>Eine Mitteilung dieser Daten (einschlie&szlig;lich Angaben zu Kommunikationskan&auml;len wie E-Mail-Adresse, Telefonnummer) erfolgt auf freiwilliger Basis. Die Daten verwenden wir zur Bearbeitung Ihres Anliegens, ggf. zur Erf&uuml;llung rechtlicher Verpflichtungen sowie f&uuml;r Verwaltungszwecke.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 b, c bzw. f DSGVO.</p>
            <p>Bei einer telefonischen Anfrage werden Ihre Daten zudem durch Telefonanwendungen und zum Teil auch &uuml;ber ein Sprachdialogsystem verarbeitet, um uns bei der Verteilung und Bearbeitung der Anfragen zu unterst&uuml;tzen.</p>
            <p>F&uuml;r unseren Kundenservice setzen wir externe Kundenserviceanbieter im Wege der Auftragsverarbeiter ein.</p>
            <p>2.3 Auftritte in Social-Media-Kan&auml;len</p>
            <p>Wir unterhalten Auftritte in Social-Media-Kan&auml;len (derzeit: Facebook, Instagram, LinkedIn, Twitter, TikTok). Soweit wir die Kontrolle &uuml;ber die Verarbeitung Ihrer Daten haben, stellen wir sicher, dass die geltenden Datenschutzbestimmungen eingehalten werden.</p>
            <p>Verantwortlich f&uuml;r die Unternehmensauftritte im Sinne der DSGVO sowie sonstiger datenschutzrechtlicher Bestimmungen sind neben uns:</p>
            <p>Facebook (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland)</p>
            <p>Instagram (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland)</p>
            <p>Twitter (Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2, D02 AX07, Irland)</p>
            <p>LinkedIn (LinkedIn Ireland Unlimited Company, Gardner House Wilton Place, Dublin 2, Irland)</p>
            <p>TikTok (TikTok Technology Limited, 10 Earlsfort Terrace, Dublin, D02 T380, Irland)</p>
            <p>Wir weisen Sie darauf hin, dass dabei Ihre Daten au&szlig;erhalb des Raumes der Europ&auml;ischen Union verarbeitet werden k&ouml;nnen.</p>
            <p>Rechtsgrundlage f&uuml;r die Verarbeitung Ihrer personenbezogenen Daten durch uns ist Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes Interesse besteht in einer effektiven Information und Kommunikation.</p>
            <p>Hier finden Sie in Bezug auf unsere Unternehmensauftritte in Social-Media-Kan&auml;len weiterf&uuml;hrende Informationen zum Datenschutzrecht:</p>
            <p>Facebook: https://www.facebook.com/notes/flixbus/facebook-fanpage-datenschutzrichtlinie/2107777085936747/</p>
            <p>Instagram: https://www.facebook.com/policy.php</p>
            <p>LinkedIn: https://www.linkedin.com/legal/privacy-policy</p>
            <p>TikTok: https://www.tiktok.com/i18n/Privacy/</p>
            <p>2.4 Meldungen auf Speakout@Flix (Hinweisgeberportal)</p>
            <p>Wenn Sie uns &uuml;ber unser Hinweisgeberportal &bdquo;Speakout@Flix&ldquo; kontaktieren, erfassen wir die personenbezogenen Daten, die Sie aus eigener Initiative bereitstellen (z. B. Name und E-Mail-Adresse). Sie k&ouml;nnen eine Meldung &uuml;ber das Webportal oder telefonisch vornehmen.</p>
            <p>Ihre personenbezogenen Daten werden nur f&uuml;r die Verarbeitung Ihrer Meldung und f&uuml;r potenzielle interne Untersuchungen, die nach Ihrer Meldung durchgef&uuml;hrt werden, verwendet.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. a DSGVO.</p>
            <p>Ihre Daten werden freiwillig zur Verf&uuml;gung gestellt und Sie k&ouml;nnen alle Meldungen anonym vornehmen.&nbsp;</p>
            <p>Gem&auml;&szlig; Art. 6 Abs. 1 lit. c DSGVO sind wir gesetzlich verpflichtet, Ihre Daten auf Aufforderung an Beh&ouml;rden zu &uuml;bermitteln.</p>
            <p>Unser Webportal nutzt einen externen Dienstleister (Convercent, Inc.) als Auftragsverarbeiter.</p>
        </Container>
    </>);
}
export default PrivacyPage
