import { Button, Form, Input, Select, Tabs } from 'antd';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PassengerForm from "./PassengerForm"
import {useDispatch, useSelector} from "react-redux";
import React, { useEffect, useState } from "react";
import { CaretLeftOutlined, CaretRightOutlined, CheckOutlined, ExclamationOutlined } from '@ant-design/icons'
import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import './passenger.css';
import "../common.css"
import { ReactComponent as TicketImg } from '../../../assets/img/ticket.svg';
import { useTranslation } from 'react-i18next';
import {
    addPassenger,
    addSeat, fetchBusLayout,
    fetchUsed,
    removePassenger,
    removeReserve, removeSeat,
    reserveSeat,
    unreserveSeat
} from "../../../slices/cart";
import {t} from "i18next";
const formatMoney = (value) => {
    return new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(
        value,
    )
}
const Passenger = ({ setSubmittable, trigger }) => {
    const dispatch = useDispatch();

    const reserved = useSelector(state => state.cart.reserved);
    const blockedSeatIds = useSelector(state => state.cart.usedSeats)
    const busSeats = useSelector(state => state.cart.busSeats);

    const [selectedTab, setSelectedTab] = useState(0);
    const passengers = useSelector(state => state.cart.passengers);
    const [freeSeats, setFreeSeats] = useState([])
    const trip = useSelector(state => state.cart.trip)
    const [items, setItems] = useState([])
    const {t} = useTranslation()
    function validate(index) {
        try {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(passengers[index].email)
                && isPossiblePhoneNumber(passengers[index].phone, parsePhoneNumber(passengers[index].phone).country) && isValidPhoneNumber(passengers[index].phone, parsePhoneNumber(passengers[index].phone).country)
                && !Object.values(passengers[index]).some(item => item === null  || item === undefined)) {
                return (true)
            }
            return (false)
        } catch (e) { console.log(e) }
    }

    const checkValidity = (index) => {
        if (trigger & !validate(index)) {
            return true
        }
        return false
    }
    const ticketPrice = () => {
        if(!trip) return 0;
         let element = [...trip.prices]
        element.sort((a, b) => a.price_amount - b.price_amount)
        // console.log(element)
        return formatMoney(element[0].price_amount)
    }

    useEffect(() => {
        if (items.every((item => item.isValid === true))) {
            setSubmittable(true)
        } else {
            console.log(items)
            setSubmittable(false)
        }
    }, [items])

    useEffect(() => {
        if (trip) {
            dispatch(fetchBusLayout(trip.bus.layoutId));
            dispatch(fetchUsed({
                routeId: trip.routeId,
                departureTripId: trip.departureTripId,
                arrivalTripId: trip.arrivalTripId
            }))
        }
    }, []);

    useEffect(() => {
        if (!busSeats) return;
        if (!blockedSeatIds) return;
        console.log('blockedSeatIds', blockedSeatIds)
        if(busSeats) {
            let ff = busSeats.filter(el => !blockedSeatIds.includes(el.id))
            setFreeSeats(ff)
        }
    }, [ busSeats, blockedSeatIds]);



    useEffect(()=> {
        let temp = []
        reserved.map((item, index) => {
            temp.push({
                key: index,
                label: (<span className="pass-tab-item"> {item.seat.category.substring(0, 3).toUpperCase() + ' ' + 'Seat: ' +
                     item.seat.number} {validate(index) && <span className="pass-tab-tick"><CheckOutlined /></span>} {checkValidity(index) && <span className="pass-tab-tick bg-danger"><ExclamationOutlined /></span>} </span>),
                children: <PassengerForm item={item} trigger={trigger} />,
                isValid: validate(index) ? true : false
            })
        });
       setItems(temp)

    }, [passengers, trigger])

    const OperationsSlot = {
        left:
            <Button
                className='common-arrow-btn d-flex justify-content-center align-items-center'
                onClick={() => {
                    if (selectedTab > 0)
                        setSelectedTab(selectedTab - 1)
                }}
            >
                <CaretLeftOutlined />
            </Button>,
        right:
            <Button
                className='common-arrow-btn d-flex justify-content-center align-items-center'
                onClick={() => {
                    if (selectedTab < reserved.length - 1)
                        setSelectedTab(selectedTab + 1)
                }}
            >
                <CaretRightOutlined />
            </Button>,
    };
    const decreaseSeat = async () => {
        try {
            let ticketId = reserved[reserved.length - 1];
            dispatch(unreserveSeat({
                tickets: [{ ticketId:ticketId.id }]
            })).then(()=> {
                dispatch(removePassenger({id:ticketId.id}))
                dispatch(removeReserve({id:ticketId.id}))

                dispatch(fetchUsed({
                    routeId:trip.routeId,
                    departureTripId: trip.departureTripId,
                    arrivalTripId: trip.arrivalTripId
                }))
                dispatch(removeSeat({index: reserved.length - 1}))
            })

            console.log('items', items)
            setSelectedTab(0)
        } catch (e) {
                console.log(e)
        }

    }
    const increaseSeat = async () => {
        try {
            let value = freeSeats[0];

            console.log('freeSeats', freeSeats)
            console.log('value', value)
            // return;
            // const originalBlockedSeats =  await dispatch(fetchUsed({
            //     routeId:trip.routeId,
            //     departureTripId: trip.departureTripId,
            //     arrivalTripId: trip.arrivalTripId
            // })).unwrap()
            //
            // if(originalBlockedSeats.includes(value.id)) {
            //     console.log(originalBlockedSeats, value.id)
            //     dispatch(fetchBusLayout(trip.bus.layoutId));
            //
            //     return;
            // }

            let reservedOriginal = await  dispatch(reserveSeat({
                tickets: [
                    {
                        routeId:trip.routeId,
                        departureTripId: trip.departureTripId,
                        arrivalTripId: trip.arrivalTripId,
                        seatId: value.id
                    }
                ]
            })).unwrap();
            dispatch(addSeat(value))
            let tickedId = reservedOriginal.find(el => el.seatId === value.id)
            dispatch(addPassenger(tickedId.id))

            setSelectedTab(items.length )
            const originalBlockedSeats =  await dispatch(fetchUsed({
                routeId:trip.routeId,
                departureTripId: trip.departureTripId,
                arrivalTripId: trip.arrivalTripId
            })).unwrap()

        } catch (rejectedValueOrSerializedError) {
            console.log('rejectedValueOrSerializedError', rejectedValueOrSerializedError)
        }
    }


    return (
        <>
            <Row className='tickets-amount extra-info-block d-flex align-items-center mb-3'>
                <Col md={8} className='d-flex align-items-center'>
                    <TicketImg style={{ transform: 'scale(1.3)'}} />
                    <p className='extra-luggage d-flex flex-column'>
                        {/* Extra luggage: */}
                        Number of tickets
                        <span>Start price: {ticketPrice()} {" "}
                            {/* each, max 20kg */}
                            each ticket
                    </span>
                    </p>
                </Col>
                <Col md={4} className='common-count-btn d-flex align-items-center justify-content-end'>
                    <Button onClick={() => { decreaseSeat() }} disabled={reserved.length <= 0}> - </Button>
                    <span className='count-bag'>{reserved.length}</span>
                    <Button onClick={() => { increaseSeat() }}> + </Button>
                </Col>
            </Row>
            {reserved.length > 0 && (
                <Tabs
                    rootClassName={passengers.length < 4 ? 'passenger-info-block' : 'passenger-info-block custom-pass-margin'}
                    activeKey={selectedTab}
                    centered={passengers.length < 4}
                    tabBarExtraContent={OperationsSlot}
                    onTabClick={(aa) => {
                        setSelectedTab(aa)
                    }}
                    defaultActiveKey={1}
                    items={items}
                />
            )}
        </>

    )
}
export default Passenger;
