
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/thumbs"

import "./swiper.css";


// import Swiper core and required modules
import SwiperCore, {
    Navigation,Thumbs
} from 'swiper/modules';

// // install Swiper modules
// SwiperCore.use([Navigation,Thumbs]);


export default function GalleryPhotos({images}) {


    const [thumbsSwiper, setThumbsSwiper] = useState(null);


    return (
        <>

            <Swiper
                modules={[Navigation, Thumbs]}
                style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }} className="thumbSwiper">
                {images.map(photo => (
                    <SwiperSlide key={'t'+photo}>
                        <img src={photo} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                modules={[Navigation, Thumbs]}
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                className="thumbSwiper2">
                {images.map(photo => (
                    <SwiperSlide key={'m'+photo}>
                        <img src={photo} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}
