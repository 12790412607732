import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import BookHeader from "./BookHeader";
import BookSeat from "./BookSeat";
import BookCart from "./BookCart";
import { useDispatch, useSelector } from "react-redux";
import Passenger from "../common/passenger/Passenger";
import { useNavigate } from "react-router-dom";
import Cargo from "../common/cargo/Cargo";
import SenderReceiver from "../common/cargo/SenderReceiver";
import Payment from "../payment/Payment";
import CargoConfirm from '../common/cargo/CargoConfirm';
import PassengerConfirm from "../common/passenger/PassengerConfirm";
import { increaseStep } from '../../slices/cart';
import Loader from '../loader/Loader'
import { useTranslation } from 'react-i18next';

const Book = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const step = useSelector(state => state.cart.step)
    const trip = useSelector(state => state.cart.trip)
    const searchType = useSelector(state => state.search.type)
    const [submittable, setSubmittable] = useState(false)
    const [trigger, setTrigger] = useState(false);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation()

    let currentStep = null;
    if (searchType === 'tickets') {
        switch (step) {
            // case 1:
            //     currentStep = <BookSeat setSubmittable={setSubmittable} />
            //     break;
            case 1:
                currentStep = <Passenger setSubmittable={setSubmittable} trigger={trigger} />
                break;
            case 2:
                currentStep = <PassengerConfirm setSubmittable={setSubmittable} trigger={trigger} />
                break;
            case 3:
                currentStep = <Payment />
                break;
            default:
                currentStep = null
        }
    } else {
        switch (step) {
            case 1:
                currentStep = <Cargo setSubmittable={setSubmittable} trigger={trigger} />
                break;
            case 2:
                currentStep = <SenderReceiver setSubmittable={setSubmittable} trigger={trigger} />
                break;
            case 3:
                currentStep = <CargoConfirm setSubmittable={setSubmittable} trigger={trigger} />
                break;
            case 4:
                currentStep = <Payment />
                break;
            default:
                currentStep = null
        }
    }

    useEffect(() => {
        if (increaseStep())
            setSubmittable(false)
    }, [increaseStep])

    function nextStep() {
        console.log('sub->', submittable, trigger)
        if (submittable) {
            dispatch(increaseStep())
            setTrigger(false)
            setLoading(true)
        }
        else {
            setTrigger(true)
            setTimeout(()=> {
                setTrigger(false)
            },1000)
        }
    }

    useEffect(() => { if (!trip) navigate('/search'); }, [trip])

    useEffect(() => {
        setLoading(true)
        setTimeout(() => setLoading(false), 1000);
    }, [step])

    return (
        <>
            <BookHeader setLoading={setLoading} />
            {loading === true
                ? <Loader loading={loading} />
                : <Container style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                    <Row>
                        <Col md={8} >
                            {currentStep}
                        </Col>
                        <Col md={4} className='seats-color-legend'>
                            {/* {searchType === "tickets" &&
                                (step === 1 && (
                                    <ul className='legend-list d-flex align-items-center justify-content-between flex-wrap gap-3 mt-4 mt-md-0'>
                                        <li className='legend-available'>
                                            {t("available")}
                                        </li>
                                        <li className='legend-selected'>
                                            {t("selected")}
                                        </li>
                                        <li className='legend-booked'>
                                            {t("booked")}
                                        </li>
                                    </ul>
                                ))
                            } */}
                            <BookCart nextStep={nextStep} trigger={trigger} />
                        </Col>
                    </Row>
                </Container>
            }
        </>
    );
};
export default Book;
