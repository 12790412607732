import React, {useEffect, useState} from 'react';
import {Container, Col, Row, Image} from 'react-bootstrap';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {useSelector, useDispatch} from 'react-redux';
import {clearArrivals, fetchDeparture, setFrom, setTicketType, setTo} from '../../slices/search';
import SliderMain from "../slider/SliderMain";
import SearchBoxMainTickets from "../searchbox/SearchBoxMainTickets";
import {Radio, Space} from 'antd';
import './main.css';
import {TwitterOutlined, InstagramOutlined, GooglePlusOutlined} from '@ant-design/icons';

import IconApp from '../../assets/img/icon_app.png';
import {ReactComponent as IconCheckmark} from '../../assets/img/icon_checkmark_outlined.svg';
import IconFree from '../../assets/img/icon_free.svg';
import IconFriendly from '../../assets/img/icon_friendly.svg';
import IconSafety from '../../assets/img/icon_safely.svg';
import IconSeat from '../../assets/img/icon_seat.svg';
import AppStore from '../../assets/img/appstore.svg';
import GooglePlay from '../../assets/img/googleplay.svg';
import apiService from "../../services/api.service";
import {useTranslation} from 'react-i18next';
import BusParkBlock from "../buspark/BusParkBlock";

const Main = () => {
    const dispatch = useDispatch();
    const selectedSearchType = useSelector(state => state.search.type)
    const itemFrom = useSelector(state => state.search.from)
    const arrivalResult = useSelector(state => state.search.arrivalResult)
    const [countryList, setCountryList] = useState([]);
    const {t} = useTranslation()
    const getNearList = async () => {
        let resp = await apiService.getLocationsListNear();
        setCountryList(resp.data);
        // if(resp.data.length)
        //     dispatch(setFrom(resp.data[0].id));
    }
    useEffect(() => {
        if (!countryList.length) {
            getNearList();
        }
    }, []);
    //const countryList = ['Berlin', 'Munich', 'Hamburg', 'Frankfurt', 'Stuttgart', 'Cologne', 'Düsseldorf', 'Dresden', 'Leipzig', 'Hanover', 'Nuremberg', 'Dortmund', 'Freiburg', 'Karlsruhe', 'Mannheim', 'Heidelberg', 'Essen', 'Bremen', 'Aachen', 'Frankfurt Airport'];

    const setBoxStyle = (event) => {
        const searchBoxStyle = document.querySelector('.search-box-block');

        if (event === 'tickets') {
            searchBoxStyle.style.borderTopRightRadius = '10px';
            searchBoxStyle.style.borderBottomRightRadius = '10px';
        } else if (event === 'cargo') {
            searchBoxStyle.style.borderTopRightRadius = '10px';
            searchBoxStyle.style.borderBottomRightRadius = '10px';
        }
    }
    useEffect(() => {
        setBoxStyle(selectedSearchType)
    }, [selectedSearchType])

    return (
        <>
            <SliderMain />
            <Container className='style-main-block'>
                <Row>
                    <Col md={12}>
                        <SearchBoxMainTickets />
                    </Col>

                </Row>
                <Row className='mb-4 style-block-row'>
                    <Col md={4} >
                        <div className='text-center friendly-block-style'>
                            <div className="icon-position">
                                <Image src={IconFriendly} />
                            </div>
                            <h6>
                                {/* Travel environmentally-friendly */}
                                {t("mainBoxHeader1")}
                            </h6>
                            <p>{t("mainBoxContent1")}</p>
                            {/*<a href="#" className='travel-link d-inline-flex justify-content-center align-items-center'>Bus travel and environment <span className='main-link-arrow'></span></a>*/}
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='text-center safety-block-style'>
                            <div className="icon-position">
                                <Image src={IconSafety} />
                            </div>
                            <h6>
                                {/* Health and Safety */}
                                {t("mainBoxHeader2")}
                            </h6>
                            <p>{t("mainBoxContent2")}</p>
                            {/*<a href="#" className='travel-link d-inline-flex justify-content-center align-items-center'>Learn more <span className='main-link-arrow'></span></a>*/}
                        </div>
                    </Col>
                    <Col md={4} >
                        <div className='text-center seat-block-style'>
                            <div className="icon-position">
                                <Image src={IconSeat} />
                            </div>
                            <h6>
                                {/* Comfort on board */}
                                {t("mainBoxHeader3")}
                            </h6>
                            <p>{t("mainBoxContent3")}</p>
                            {/*<a href="#" className='travel-link d-inline-flex justify-content-center align-items-center'>Our onboard service<span className='main-link-arrow'></span></a>*/}
                        </div>
                    </Col>
                </Row>
                <Row className={'mt-2 mb-4'}>
                    <Col md={6} className='mb-3 mb-md-0'>
                        <div className="txt-box custom-card-style">
                            <h5 className="s-24 w-700">  {t("mainOrange1")}</h5>
                            <p>{t("mainOrange2")}</p>
                            <p>{t("mainOrange3")}</p>
                        </div>
                    </Col>

                    <Col md={6} className='achievements-block'>
                        <div className="custom-card-style">
                            <h5 className={'ml-6'} >{t("mainOrange4")}:</h5>
                            <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 1, 900: 1}}>
                                <Masonry>
                                    <div className='main-text-info-block'>
                                        <div className="icon-position icon_checkmark_outlined">
                                            {/* <Image src={IconCheckmark} height={30} /> */}
                                            <IconCheckmark height={30} />
                                        </div>
                                        <h4>{t("mainOrange5")}</h4>
                                        <p>{t("mainOrange6")}</p>
                                    </div>
                                    <div className='main-text-info-block'>
                                        <div className="icon-position icon_checkmark_outlined">
                                            {/* <Image src={IconCheckmark} height={30} /> */}
                                            <IconCheckmark height={30} />
                                        </div>
                                        <h4>{t("mainOrange7")}</h4>
                                        <p>{t("mainOrange8")}</p>
                                    </div>
                                    <div className='main-text-info-block'>
                                        <div className="icon-position icon_checkmark_outlined">
                                            {/* <Image src={IconCheckmark} height={30} /> */}
                                            <IconCheckmark height={30} />
                                        </div>
                                        <h4>{t("mainOrange9")}</h4>
                                        <p>{t("mainOrange10")}</p>
                                    </div>
                                </Masonry>
                            </ResponsiveMasonry>
                        </div>
                    </Col>
                </Row>

            </Container>

            <BusParkBlock />
        </>
    )
}
export default Main;
