import SliderMain from "../slider/SliderMain";
import {Col, Container, Image, Row} from "react-bootstrap";

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import apiService from "../../services/api.service";
import GalleryPhotos from "./GalleryPhotos";
import BusParkBlock from "./BusParkBlock";
import {Button} from "antd";
import { ReactComponent as BusIcon } from '../../assets/img/icon_bus.svg';

import { ReactComponent as EmailIcon } from '../../assets/img/icon_mail.svg';
import { ReactComponent as PhoneIcon } from '../../assets/img/icon-phone.svg';
import { ReactComponent as SeatIcon } from '../../assets/img/icon_seat.svg';
import { ReactComponent as WcIcon } from '../../assets/img/wc.svg';
import TVIcon from '../../assets/img/tv.png';
import BGICon from '../../assets/img/bg.png';


import {useTranslation} from "react-i18next";
import bus from "../../assets/img/bus_image.png";
const BusParkPage = () => {
    const {id} = useParams();
    const [countryList, setCountryList] = useState([]);
    const { t } = useTranslation()
    const [bus, setBus ] = useState(null)
    const [allBuses, setallBuses ] = useState([])
    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const getBus = async () => {
          let resp = await  apiService.getBusRent();
            setBus(resp.data.find(el=> el.id == id))
            setallBuses(resp.data)
    }
    useEffect(()=> {
        if(!bus) getBus();
        else
        {
            if(allBuses.length) {
                setBus(allBuses.find(el => el.id == id))
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            else
                window.location.reload();
        }


    }, [id]);
    if(!bus) return (<></>);
    return (
        <>
            <SliderMain />
            <Container className='pt-5 mb-5'>
                <Row>
                    <Col sm={12} md={12} lg={6} className={'pb-4'}>
                        <h4>{bus.name}</h4>
                        <div className='busParkTitleIcons'>

                            <div>
                                <SeatIcon  />
                                <span>{bus.p.seats}</span>
                            </div>
                            <div>
                                <WcIcon/>
                            </div>
                            <div>
                                <Image alt='bus' src={TVIcon}  />
                            </div>
                            <div>
                                <Image alt='bus' src={BGICon}  />
                            </div>
                        </div>
                        <GalleryPhotos className={'GalleryPhotos'} images={bus.photos}/>
                    </Col>
                    <Col sm={12} md={12} lg={6} >
                        <div dangerouslySetInnerHTML={{__html: t("busInfo")}}></div>
                    </Col>
                </Row>

                <Row className='tickets-amount extra-info-block d-flex align-items-center mb-3 '>
                    <Col sm={12} md={6} className='d-flex align-items-center'>
                        <BusIcon style={{ transform: 'scale(1.3)'}} />
                        <p className='extra-luggage d-flex flex-column'>
                            {/* Extra luggage: */}
                            Связаться с BusLines
                            <span>По телефону или емэйлу</span>
                        </p>
                    </Col>
                    <Col sm={12} md={6} className='common-count-btn custom-me' >
                        <div className="d-flex align-items-center contact-bus-page">
                            <PhoneIcon style={{
                                width:'30px',
                                height:'30px'
                            }}  />
                            <span>+49 176 8888888</span>
                        </div>
                        <div className="d-flex align-items-center">
                             <EmailIcon
                                 className={'email-icon-svg'}
                                 style={{
                                     fill:'black',
                                     width:'30px',
                                     height:'30px'
                                 }} />
                            <span>rent@bus-lines.de</span>
                        </div>
                    </Col>
                </Row>

            </Container>
            <BusParkBlock />
        </>
    )
}
export default BusParkPage;
