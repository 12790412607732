import './App.css';
import {Routes, Route, useLocation, Navigate} from "react-router-dom";
import Home from "./components/layout/Home";
import Main from "./components/main/Main";
import Search from "./components/search/Search";
import Book from "./components/book/Book";
import ResendTicket from './components/resendticket/ResendTicket';
import {useEffect} from "react";
import {resetCart} from "./slices/search";
import {useDispatch, useSelector} from "react-redux";
import TransactionSuccess from './components/transactionresult/TransactionSuccess';
import TransactionError from './components/transactionresult/TransactionError';
import StripeProcessing from './components/payment/StripeProcessing';
import BusParkPage from "./components/buspark/BusParkPage";

import PrivacyPage from "./components/privacy/PrivacyPage";
import PassRights from "./components/privacy/PassRights";
import LegalPage  from "./components/privacy/LegalPage";

function App() {
    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
        if(location.pathname === "/search") {
            dispatch(resetCart())
        }
    }, [location]);
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}>
            <Route index element={<Main />}/>
            <Route path="search/:departure?/:arrival?/" element={<Search />}/>
            <Route path="buspark/:id?/" element={<BusParkPage />}/>
            <Route path="payment/success" element={<TransactionSuccess />}/>
            <Route path="payment/error" element={<TransactionError />}/>
            <Route path="book" element={<Book />}/>
            <Route path="resend" element={<ResendTicket />}/>
            <Route path="payment/processing" element={<StripeProcessing />}/>

            <Route path="privacy" element={<PrivacyPage />}/>
            <Route path="rights" element={<PassRights />}/>
            <Route path="legal" element={<LegalPage />}/>

            <Route path="*" element={<Navigate to={"/"} />}/>
        </Route>
      </Routes>
    </>
  );
}

export default App;
