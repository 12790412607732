import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';
import { ArrowRightOutlined, LeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { decreaseSteo, decreaseStep } from "../../slices/cart";
import { useNavigate } from "react-router-dom";
import { Statistic } from 'antd';
import { resetCart } from "../../slices/search";
import './book.css';
import { useTranslation } from 'react-i18next';
import timer from "../../assets/img/icon_timer.svg"

const { Countdown } = Statistic;

const calculateDuration = eventTime => moment.duration(Math.max(eventTime - (Math.floor(Date.now() / 1000)), 0), 'seconds');

const CountdownLocal = ({ eventTime, interval }) => {
    const [duration, setDuration] = useState(calculateDuration(eventTime));
    const timerRef = useRef(0);
    const timerCallback = useCallback(() => {
        setDuration(calculateDuration(eventTime));
    }, [eventTime])

    useEffect(() => {
        timerRef.current = setInterval(timerCallback, interval);
        return () => {
            clearInterval(timerRef.current);
        }
    }, [eventTime]);

    return (
        <div>
            {duration.minutes()}:{duration.seconds()} min
        </div>
    )
}
const BookHeader = ({ setLoading }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const endSessionTime = useSelector(state => state.cart.endSessionTime);
    const trip = useSelector(state => state.cart.trip);
    const step = useSelector(state => state.cart.step);

    const goBack = () => {
        if (step > 1) {
            setLoading(true)
            dispatch(decreaseStep())
        } else {
            navigate('/search')
        }
    }
    const { t } = useTranslation()
    return (<>
        <div className='book-header-block'>
            <Container>
                <div className='back-button-style d-inline-flex align-items-center' onClick={() => goBack()}><LeftOutlined />
                    {/* Back */}
                    {t("back")}
                </div>
                <Row className='results-items' >
                    <Col md={8} className='destination-col'>
                        <Row className='destination-result'>
                            <Col md={6}>
                                <div className='items-date-block mb-3'>
                                    <div className='strong-result-text'>
                                        <b>{moment(trip?.departureDate).format('HH:mm')} | {moment(trip?.departureDate).format('DD MMMM YYYY')}</b>
                                    </div>
                                </div>
                                <div className='items-direction'>
                                    <div className='strong-result-text'><b>{trip?.departureLocation.name}</b></div>
                                    <div>{trip?.departureLocation.country.name}</div>
                                </div>
                            </Col>
                            <Col md={6} className='items-arrival-block'>
                                <ArrowRightOutlined style={{ color: 'var(--base-color-green)' }} />
                                <div className='mb-3'>
                                    <div className='strong-result-text'>
                                        <b>{moment(trip?.arrivalDate).format('HH:mm')} | {moment(trip?.arrivalDate).format('DD MMMM YYYY')}</b>
                                    </div>
                                </div>
                                <div className='strong-result-text'><b>{trip?.arrivalLocation.name}</b></div>
                                <div>{trip?.arrivalLocation.country.name}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <div className='destination-result timer-block d-flex justify-content-center align-items-center'>
                            {/* <Image src={timer} width={50} height={50} /> */}

                            <Countdown
                                title={t("timeRemaining")}
                                format={"mm:ss"}
                                suffix={"min"}
                                value={endSessionTime * 1000}
                                onFinish={() => navigate("/search")}
                                
                            />
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    </>);
};

export default BookHeader;
