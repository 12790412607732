import axios from "axios";
import {API_URL} from "../constants";
import md5 from "md5"
const authHeader = () => {
    const timestamp = Date.now();
    const key = "77cb0b934c5cbac2df8739482aa28be5215823a08b585ba81e25d7b3b2b58678de759149a1e985e355ccc1f9036bc45001b87a65e0d02cf6f6ec03fd49325330";

    const token = md5(key+timestamp);
    return { 'Authorization': 'Token token='+token, 'timestamp':timestamp};
}
const getBusRent = () => {
    return axios.get(API_URL + '/busrent/info')
}

const getLocationsList = () => {
    return axios.get(API_URL + '/country/list')
}

const getLocationsListNear = () => {
    return axios.get(API_URL + '/city/nearlist')
}
const fetchSearch = (data, type) => {
    let where = type === 'tickets' ? 'route': 'cargo'
    return axios.post(API_URL + '/'+where+'/search', data, { headers: authHeader() })
}
const fetchArrival = (data) => {
    return axios.post(API_URL + '/route/arrival', data, { headers: authHeader() })
}
const fetchDeparture = (data) => {
    return axios.post(API_URL + '/route/departure', data, { headers: authHeader() })
}
const fetchUsed = (data) => {
    return axios.post(API_URL + '/route/seats/used', data, { headers: authHeader() })
}
const reserveSeat = (data)=> {
    return axios.post(API_URL + '/ticket/reserve', data, { headers: authHeader() })
}
const unreserveSeat = (data)=> {
    return axios.post(API_URL + '/ticket/unreserve', data, { headers: authHeader() })
}

const resendTicket = (data) => {
    return axios.post(API_URL + '/ticket/restoreByEmail', data, { headers: authHeader() })
}
const resendTicketPay = (data) => {
    return axios.post(API_URL + '/ticket/restoreByPay', data, { headers: authHeader() })
}

const paymentIntent = (data) => {
    return axios.post(API_URL + '/ticket/create-payment-intent', data, { headers: authHeader() })
}

/*
passengers: [{
            name,
            surname,
            gender,
            kind,
            birthday,
            passport,
            phone,
            email,
            subscribe,
            ticketId,
            bagageCount
}]
*/

const passengerAdd = (data) => {
    return axios.post(API_URL + '/ticket/passenger/add', data, { headers: authHeader() })
}
const cargoPassengerAdd = (data) => {
    return axios.post(API_URL + '/cargo/passenger/add', data, { headers: authHeader() })
}
const cargoTicketAdd = (data) => {
    return axios.post(API_URL + '/cargo/ticket/add', data, { headers: authHeader() })
}
const payCargo = (data) => {
    return axios.post(API_URL + '/cargo/payment/add', data, { headers: authHeader() })
}

/*
    {
        tickets : [{ticketId}],
        transactionId,
        method,
        sum
    }
 */
const payTickets = (data) => {
    return axios.post(API_URL + '/ticket/pay', data, { headers: authHeader() })
}

const fetchBusLayout = (id)=> {
    return axios.get(API_URL + '/bus/layout/'+id)
}

const apiService = {
    getLocationsList,
    fetchSearch,fetchUsed,
    reserveSeat,unreserveSeat,
    fetchBusLayout,
    resendTicket,
    resendTicketPay,
    getLocationsListNear,
    paymentIntent,
    passengerAdd,
    payTickets,
    payCargo,
    cargoTicketAdd,
    cargoPassengerAdd,
    fetchArrival,
    fetchDeparture,
    getBusRent
};
export default apiService;
