import React, {useEffect, useState} from 'react'
import SliderMain from '../slider/SliderMain'
import "../resendticket/resendticket.css"
import "./transaction.css"
import busstop from "../../assets/img/bus_stop_image.png"
import bus from "../../assets/img/bus_image.png"
import { ReactComponent as SuccessImage } from "../../assets/img/icon_success.svg"
import { Image, Container } from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import pdffile from "../../assets/img/pdf_file.svg";
import moment from "moment/moment";
import download from "../../assets/img/download.svg";
import share from "../../assets/img/share.svg";
import apiService from "../../services/api.service";
import {IMG_URL, API_URL} from "../../constants/index";
const TransactionSuccess = () => {
  const { t } = useTranslation()
  const [tickets, setTickets] = useState([])

  const location = useLocation();


  async function resendTicketFn(e) {
      const params = Object.fromEntries(new URLSearchParams(location.search));
      if(!params.id) return;
      const res = await apiService.resendTicketPay({ paymentId: params.id });
      setTickets(res.data.tickets)

  }
  const onButtonClick = (id) => {
    fetch(`${IMG_URL}/uploads/tickets/ticket/${id}/ticket.pdf`).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'ticket.pdf';
        alink.click();
      })
    })
  }
  useEffect(() => {
    resendTicketFn();
  }, []);

  return (
    <>
      <SliderMain />
      <Container className='resend-main'>
        <div className='resend-ticket-info'>
          <div className='resend-ticket-info-wrapper'>
            <div className='d-flex flex-column justify-content-end transaction-result-wrapper'>
              <div className='d-flex align-items-center justify-content-center  position-relative'>
                <SuccessImage height={36} className="transaction-result-icon" />
                <div className='transaction-result-border-b text-center'>
                  <h3>
                    {t("transactionSuccess")}
                    {/* successful transaction */}

                  </h3>
                </div>
              </div>
              <div className='align-self-end'>
                <p className='success'>
                  {t("ticketDetailsRight")}
                  {/* Your ticket details were sent to your
                  <br /> email address. */}
                </p>
              </div>
            </div>
            <div className='resend-image-container'>
              <Image src={bus} className='resend-image-bus' />
              <Image src={busstop} className='resend-image' />
            </div>
            <div className='transaction-result-btn-container'>
              <Link to={"/"} className='transaction-result-btn'>
                {/* Return to Home Page */}
                {t("returnHome")}
                </Link>
            </div>

            {tickets && tickets.map((ticket) => <div key={ticket.id} className='resend-ticket-booking-info'>
                  <div className='resend-ticket-booking-number'>
                    <div>
                      <h5>Booking number:</h5>
                      <h4>#{ticket.code}</h4>
                    </div>
                    <Link to={`${IMG_URL}/uploads/tickets/ticket/${ticket.id}/ticket.pdf`} target='_blank' rel='noopener noreferrer'>
                      <img alt='pdffile' src={pdffile} width={28} />
                    </Link>
                  </div>
                  <div className='resend-ticket-booking-details'>
                    <div className='departure'>
                      {/*<h5>Booking details:</h5>*/}
                      <h6>{moment(ticket.departureTrip.departureDate).format("HH:mm | DD MMM YYYY")}</h6>
                      <h4>{ticket.departureTrip.station.departure.address?.split(",")[0]}</h4>
                      <h6>{ticket.departureTrip.station.departure.country.name}</h6></div>
                    <div className='arrival'>
                      {/*<h5>empty</h5>*/}
                      <div className='icons-container'>
                        <h6>{moment(ticket.arrivalTrip.departureDate).format("HH:mm | DD MMM YYYY")}</h6>
                        <div className='icons'>
                          {/*<button className='bland-btn' onClick={() => onButtonClick(ticket.id)} >*/}
                          {/*  <img alt='download' src={download} width={20} />*/}
                          {/*</button>*/}
                          {/*<button className='bland-btn'>*/}
                          {/*  <img alt='share' src={share} width={20} />*/}
                          {/*</button>*/}
                        </div>
                      </div>
                      <h4>{ticket.arrivalTrip.station.departure.address?.split(",")[0]}</h4>
                      <h6>{ticket.arrivalTrip.station.departure.country.name}</h6></div>
                  </div>

                </div>
            )}
          </div>

        </div>
      </Container></>
  )
}

export default TransactionSuccess
