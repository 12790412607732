import {useTranslation} from "react-i18next";
import {Container} from "react-bootstrap";

const LegalPage = () => {

    const { t } = useTranslation()


    return (<>
        <Container>
            <h2>Allgemeine Beförderungsbedingungen ABB</h2>
            <p>Allgemeine Bef&ouml;rderungsbedingungen - Auswahl</p>
            <p>Bitte w&auml;hlen Sie die f&uuml;r Ihre Fahrt geltenden Bef&ouml;rderungsbedingungen/AGB Ihres Bef&ouml;rderers (diesen erfahren Sie im Buchungsprozess und auf Ihrem Ticket):</p>
            <p>FlixGesellschaften:</p>
            <p>FlixBus B.V.</p>
            <p>Flix Chile SpA</p>
            <p>FlixBus CEE South d.o.o.</p>
            <p>FlixBus CZ</p>
            <p>FlixBus DACH GmbH</p>
            <p>FlixBus Danmark ApS</p>
            <p>FlixBus Espa&ntilde;a S.L.U.</p>
            <p>FlixBus Estonia O&Uuml;</p>
            <p>FlixBus France SARL</p>
            <p>FlixBus Hungary Kft.</p>
            <p>FlixBus Inc.</p>
            <p>FlixBus India Private Limited</p>
            <p>FlixBus Italia S.r.l.</p>
            <p>FlixBus North GmbH CEE</p>
            <p>FlixBus Norway</p>
            <p>FlixBus Polska Sp. z.o.o.</p>
            <p>FlixBus Portugal, Unipessoal Lda</p>
            <p>FlixBus RO SRL</p>
            <p>FlixBus RUS LLC</p>
            <p>FlixBus Sverige AB</p>
            <p>FlixBus UK Inc.</p>
            <p>Flix SE</p>
            <p>FlixTrain</p>
            <p>Rodbillet ApS</p>
            <p>Swebus Express AB</p>
            <p>Kooperationspartner:</p>
            <p>Air Decker</p>
            <p>Air Pullman</p>
            <p>All Aboard America</p>
            <p>Aquabus Newco, S.L.</p>
            <p>ASIK LLC</p>
            <p>ATVO</p>
            <p>Auto Via&ccedil;&atilde;o Gadotti</p>
            <p>Autocares Lazara</p>
            <p>Autoservizi Preite S.r.l.</p>
            <p>Babić-Bisstours d.o.o.</p>
            <p>Badajoz Sevilla Bus SL</p>
            <p>Baltic Shuttle</p>
            <p>BC Ferries Connector</p>
            <p>Berry Coaches</p>
            <p>BP Tour</p>
            <p>Buscoop (4Bus)</p>
            <p>CATEDRAL TURISMO</p>
            <p>Centrotrans - Eurolines d.d.</p>
            <p>Cooperativa Interurbana Andorrana S.A.</p>
            <p>Croatia Bus d.o.o.</p>
            <p>DirectBus (Autocars Nadal SAU)</p>
            <p>Dr. Richard Linien GmbH &amp; Co. KG</p>
            <p>Eindhovense Toeristen Service B.V.</p>
            <p>Eixbus, S.A.</p>
            <p>Estonian Lines</p>
            <p>Euroclub (OOO &ldquo;EUROCLUB&rdquo;)</p>
            <p>Eurolines SA</p>
            <p>Europa Bus d.o.o.</p>
            <p>Express Arrow</p>
            <p>Expresso Adamantina</p>
            <p>Expresso Sat&eacute;lite Norte</p>
            <p>Expressway</p>
            <p>Falck Omnibus AB (Visit Abisko AB)</p>
            <p>FILS d.o.o.</p>
            <p>First Glasgow (Glasgow Airport Express)</p>
            <p>Flightlink</p>
            <p>Flybus srl/Cortina express</p>
            <p>Freiburger Reisedienst GmbH &amp; Co. KG</p>
            <p>Fudeks d.o.o.</p>
            <p>Garo Reisen d.o.o.</p>
            <p>Golub Trans D.O.O. Barič</p>
            <p>Gray Line of San Francisco</p>
            <p>Grodnooblavtotrans OAO</p>
            <p>H&auml;rjedalingen</p>
            <p>Heathrow Express Operating Company Ltd</p>
            <p>Hontarenko Pe</p>
            <p>HŽ Putnički prijevoz d.o.o. (HZPP)</p>
            <p>IAS Touring S.r.l.</p>
            <p>IDO TOURS d.o.o.</p>
            <p>Kaleia Travel</p>
            <p>Kamil Ko&ccedil;</p>
            <p>Karat-S AD</p>
            <p>LAJKONIK</p>
            <p>La Requenense Autobuses CL. SAU</p>
            <p>Leo Express s.r.o</p>
            <p>Lubina Busfahrten GmbH</p>
            <p>Maldeasa SL (Monbus)</p>
            <p>MasExpressen</p>
            <p>National Park Express</p>
            <p>Neobas d.o.o.</p>
            <p>Nederlands Spoorwegen (NS)</p>
            <p>NOVATEL, S.A.U</p>
            <p>ОЛАН-ТУР</p>
            <p>Ovnitur - Viagens e Turismo, Lda.</p>
            <p>OvR ride, LLC</p>
            <p>PKS Elbląg sp. z o.o.</p>
            <p>PKS Gdańsk (Przedsiębiorstwo Komunikacji Samochodowej Gdańsk sp. z o.o.)</p>
            <p>Premier Coach - Vermont Translines</p>
            <p>Prijevoz Francuzević</p>
            <p>Prijevoznički obrt Antonio Tours</p>
            <p>Prijevoznički Obrt Josip Knežević</p>
            <p>Primar Navega&ccedil;&otilde;es e Turismo</p>
            <p>Racic Eurobus BG</p>
            <p>Ride Solutions</p>
            <p>RJ Autocares SL</p>
            <p>Salt Lake Express</p>
            <p>Santa Barbara Airbus</p>
            <p>Shuttlecoach by Kupers</p>
            <p>Shuttle Oregon</p>
            <p>Simeonidis Tours</p>
            <p>Sirmiumbus d.o.o.</p>
            <p>SIT</p>
            <p>Slavonija Bus d.o.o.</p>
            <p>South Tahoe Airporter</p>
            <p>SP Lasta AD</p>
            <p>Tapanis Buss i Haparanda AB</p>
            <p>Thinggaard Express</p>
            <p>TRADO-BUS, s.r.o</p>
            <p>Trans City</p>
            <p>Transfero</p>
            <p>TRANSPORTES SANTA MARIA LTDA.</p>
            <p>Transports Electrics Interurbans, S.A. (Teisa)</p>
            <p>Transtempo LLC</p>
            <p>Transturist d.d.</p>
            <p>UAB Olego Transportas</p>
            <p>UTE - XG517 (Castromil/Monbus)</p>
            <p>UTE - XG817 (Castromil/MonBus)</p>
            <p>V&auml;sttrafik</p>
            <p>VIA&Ccedil;&Atilde;O ESMERALDA</p>
            <p>VIA&Ccedil;&Atilde;O LUXOR (LEVARE)</p>
            <p>VIGO-BARCELONA, S.A. (Monbus)</p>
            <p>Viliam Turan &ndash; TURANCAR</p>
            <p>Vincek d.o.o.</p>
            <p>Volpi Licurgo</p>
            <p>VS &amp; Perssons Bussar AB (SGS Bussen)</p>
            <p>Wanda Coach</p>
            <p>Wenatchee Valley Shuttle</p>
            <p>WESTbahn</p>
            <p>Ybuss AB</p>
            <p>ZAK Tourist</p>
            <p>Zelenogradsk Trans</p>
        </Container>
    </>);
}
export default LegalPage
