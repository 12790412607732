export const LANGUAGES = [
    { label: "Русский", code: "ru" },
    // { label: "English", code: "en" },
    { label: "Deutsch", code: "de" },
    // { label: "Romanian", code: "ro" },
];
//export const API_URL= "https://vbus-api.mindscape.xyz/api/device";
export const API_URL = process.env.REACT_APP_API_URL;
export const WEB_URL = process.env.REACT_APP_WEB_URL;
export const IMG_URL = process.env.REACT_APP_API_IMG_URL;
