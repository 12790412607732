import {useTranslation} from "react-i18next";
import {Container} from "react-bootstrap";

const PassRights = () => {

    const { t } = useTranslation()


    return (<>
        <Container>

            <h2>Allgemeine Geschäfts- und Buchungsbedingungen AGB</h2>
            <p>1 Geltungsbereich</p>
            <p>1.1 Die Allgemeinen Gesch&auml;ftsbedingungen und Besondere Buchungsbedingungen gelten f&uuml;r die Nutzung der Webportale von FlixBus (einschlie&szlig;lich mobiler Anwendungen, mittels der die Kunden auf solche Webportale zugreifen k&ouml;nnen) sowie f&uuml;r die Buchung von Reisen bei FlixBus.</p>
            <p>1.2 Bitte beachten Sie, dass diese Allgemeinen Gesch&auml;ftsbedingungen und Besondere Buchungsbedingungen nicht f&uuml;r die folgenden F&auml;lle, die durch separate Gesch&auml;ftsbedingungen abgedeckt sind, gelten:</p>
            <p>a) Auf seinen Webportalen erm&ouml;glicht FlixBus auch die Auflistung und Buchung von Bodentransportdiensten, die von Dritten angeboten werden, die von FlixBus autorisiert sind, bestimmte Reisen auf seinen Webportalen zu ver&ouml;ffentlichen und anzuzeigen, f&uuml;r die FlixBus jedoch nicht der Vertragspartner in Bezug auf die Buchung von Tickets ist (&bdquo;Angebote von White-Label-Bef&ouml;rderern&ldquo;). Diese Angebote stellen keine Reisebuchung bei FlixBus dar, da FlixBus in diesem Fall lediglich die Buchung von Angeboten von White-Label-Bef&ouml;rderern &uuml;ber die Website booking.flixbus.com oder eine mobile Anwendung, mittels der die Kunden auf die Website zugreifen k&ouml;nnen, erm&ouml;glicht (zusammenfassend die &bdquo;White-Label-Plattform&ldquo; genannt). Die Verf&uuml;gbarkeit eines Angebots eines White-Label-Bef&ouml;rderers wird w&auml;hrend des Buchungsprozesses durch die Weiterleitung von den FlixBus-Webportalen auf die White-Label-Plattform angezeigt. Der Zugriff der Kunden auf die White-Label-Plattform sowie die Gesch&auml;ftsbedingungen bez&uuml;glich der von FlixBus und seinen Partnern bereitgestellten Dienstleistungen im Zusammenhang mit der Buchung von Angeboten von White-Label-Bef&ouml;rderern auf der White-Label-Plattform unterliegen den separaten Gesch&auml;ftsbedingungen f&uuml;r die White-Label-Plattform.</p>
            <p>b) Die Gesch&auml;ftsbedingungen f&uuml;r die Bef&ouml;rderung von Fahrg&auml;sten unterliegen separaten Gesch&auml;ftsbedingungen, die vom jeweiligen vertraglichen Bef&ouml;rderer abh&auml;ngig sind. Der entsprechende vertragliche Bef&ouml;rderer (&bdquo;Bef&ouml;rderer&ldquo;) sowie die entsprechenden Bef&ouml;rderungsbedingungen (&bdquo;Gesch&auml;ftsbedingungen des Bef&ouml;rderers&ldquo;) werden w&auml;hrend des Such- und Buchungsprozesses angegeben.</p>
            <p>2 Vertragspartner</p>
            <p>Vertragspartner f&uuml;r die Buchung der Fahrten (Ticketverk&auml;ufer) und die Nutzung der Webportale ist die Flix SE, Friedenheimer Br&uuml;cke 16, 80639 M&uuml;nchen, nachfolgend Flix genannt.</p>
            <p>3 Kommerzielle Nutzung des Webportals</p>
            <p>3.1 Preisvergleichsseiten k&ouml;nnen einen schriftlichen Vertrag mit der Flix abschlie&szlig;en, in dem ihnen gestattet ist, Preise sowie Fahrpl&auml;ne der Flix zu erhalten, zu verarbeiten und zu ver&ouml;ffentlichen.</p>
            <p>3.2 Es ist nicht gestattet die Webportale der Flix f&uuml;r nicht-private oder kommerzielle Anliegen zu verwenden. Das Benutzen von automatisierten Systemen um Daten von dieser Webseite f&uuml;r kommerzielle Nutzung (&bdquo;Screen Scraping&ldquo;) zu extrahieren ist verboten. Die Flix beh&auml;lt sich vor, Zuwiderhandlung entsprechend zu verfolgen.</p>
            <p>4 Zahlung und Gutscheine</p>
            <p>4.1 Fahrausweise k&ouml;nnen je nach Buchungsstelle mit verschiedenen Zahlungsmethoden bezahlt werden:</p>
            <p>Im Internet: PayPal, Kreditkarte (Mastercard/Visa/Amex), Sofort&Uuml;berweisung, iDeal, Postfinance, Carte Bleue, Dotpay, Google Pay, Apple Pay. Wir behalten uns bei jeder Buchung vor, bestimmte Zahlarten nicht anzubieten und auf andere Zahlarten zu verweisen.</p>
            <p>An Bord der Fahrzeuge: Bar.</p>
            <p>In Verkaufsstellen / Reiseb&uuml;ros: Alle von der Agentur angebotenen Zahlungsmittel, in jedem Fall aber Barzahlung. Wenn das Bef&ouml;rderungsmittel gleichzeitig die Verkaufsstelle ist, k&ouml;nnen die Zahlungsmethoden auf Barzahlung oder Kreditkartenzahlung beschr&auml;nkt sein.</p>
            <p>4.2 [Aufgehoben]</p>
            <p>4.2.1 [Aufgehoben]&nbsp;</p>
            <p>4.2.2 Kauf per Kreditkarte</p>
            <p>Bei Zahlung per Kreditkarte erfolgt die Belastung des Kundenkontos mit Abschluss der Buchung. Durch Zahlung per Kreditkarte weist der Kunde das von ihm genutzte Kreditinstitut bereits bei Buchung an, Flix oder einem von ihr beauftragten Dritten im Falle einer R&uuml;ckbuchung auf Aufforderung den Kundennamen und die vollst&auml;ndige Anschrift mitzuteilen, damit Flix seine Forderungen gegen&uuml;ber dem Kunden geltend machen kann.</p>
            <p>4.2.3 Nach erfolglosem Mahnlauf durch Flix werden die Forderungen zur weiteren Bearbeitung an einen externen Dienstleister &uuml;bergeben. Flix kann dazu alle erforderlichen personenbezogenen Daten der Schuldner an diesen externen Dienstleister &uuml;bermitteln.</p>
            <p>4.2.4 Aufrechnung; Zur&uuml;ckbehaltungsrecht</p>
            <p>4.2.4.1 Ein Recht zur Aufrechnung steht dem Kunden nur zu, wenn seine Gegenanspr&uuml;che rechtskr&auml;ftig festgestellt oder von uns unbestritten oder anerkannt sind. Au&szlig;erdem hat er ein Zur&uuml;ckbehaltungsrecht nur, wenn und soweit sein Gegenanspruch auf dem gleichen Vertragsverh&auml;ltnis beruht.</p>
            <p>4.2.4.2 Befindet sich der Kunde uns gegen&uuml;ber mit irgendwelchen Zahlungsverpflichtungen im Verzug, so werden s&auml;mtliche bestehende Forderungen aus dem gleichen Vertragsverh&auml;ltnis sofort f&auml;llig.</p>
            <p>4.3 Einl&ouml;sung von Gutscheinen:</p>
            <p>4.3.1 Pro Buchung kann maximal ein Gutschein eingel&ouml;st werden. Gutscheine k&ouml;nnen nur online oder in unseren Vertriebsagenturen eingel&ouml;st werden.</p>
            <p>Gutscheine mit einem monet&auml;ren Wert, sogenannte Wertgutscheine, sind auf den gesamten Warenkorb anrechenbar.</p>
            <p>Prozentgutscheine und Freifahrt-Gutscheine k&ouml;nnen nur auf den reinen Fahrpreis angerechnet werden. F&uuml;r zus&auml;tzliche Service-Leistungen (bspw. Service-Pauschalen, Aufpreis f&uuml;r Fahrr&auml;der oder Gep&auml;ck) sind diese nicht einl&ouml;sbar.</p>
            <p>4.3.2 Bei den durch Flix durchgef&uuml;hrten Gutscheinaktionen im Rahmen von Werbema&szlig;nahmen sind der Bezug und die Verwendung von Gutscheinen auf drei Gutscheine pro Person beschr&auml;nkt. Sollte eine Person mehr als drei Gutscheine derselben Gutscheinaktion einl&ouml;sen, kann Flix die Buchungen stornieren, die &uuml;ber die ersten drei hinausgehen. Von dieser Regelung kann im Rahmen von Sonderaktionen abgewichen werden.</p>
            <p>4.3.3 Gutscheine, die unentgeltlich im Rahmen einer Werbema&szlig;nahme oder aus Kulanz ausgestellt wurden, verfallen mit dem ersten Buchungsvorgang.</p>
            <p>4.3.4 Eine gewerbsm&auml;&szlig;ige Nutzung, insbesondere der Weiterverkauf von Gutscheinen ist untersagt und wird durch Flix mit Sperrung der Tickets und /oder Schadensersatzanspr&uuml;chen sanktioniert.</p>
            <p>4.3.5 Eine Auszahlung des Guthabens eines Gutscheins ist ausgeschlossen.</p>
            <p>4.4 Einl&ouml;sen von Interflix-Gutscheincodes</p>
            <p>4.4.1 Pro Buchung kann maximal ein Gutscheincode eingel&ouml;st werden. Die Gutscheinecodes werden automatisch innerhalb von 48h aktiviert und k&ouml;nnen nur online oder in unseren Partneragenturen eingel&ouml;st werden.</p>
            <p>4.4.2 Die Gutscheincodes sind drei Monate lang g&uuml;ltig.</p>
            <p>4.4.3 Es kann nur eine direkte Verbindung gebucht werden, mit Ausnahme von Hin- und R&uuml;ckreisen. Der Abfahrtsort muss nicht der Zielort sein.</p>
            <p>4.4.4 Die Gutscheine sind personengebunden und nicht &uuml;bertragbar.</p>
            <p>4.4.5 Umbuchungen k&ouml;nnen ausschlie&szlig;lich &uuml;ber den Kundenservice get&auml;tigt werden. Eine Stornierung ist ausgeschlossen.</p>
            <p>4.4.6 Eine gewerbsm&auml;&szlig;ige Nutzung, insbesondere der Weiterverkauf von Gutscheincodes, ist untersagt und wird von der Firma Flix mit Sperrung der Tickets und/oder Schadenersatzanspr&uuml;chen sanktioniert.</p>
            <p>4.4.7 Die Auszahlung des Guthabens eines Gutscheins ist ausgeschlossen.</p>
            <p>4.4.8 Jede Fahrt, die &uuml;ber einen Gutschein gebucht wird, erfolgt separat und getrennt von den &uuml;brigen Fahrten.</p>
            <p>4.4.9 Der Fahrgast hat das Recht, binnen 14 Tagen vom Kaufvertrag zur&uuml;ck zu treten. Der R&uuml;cktritt ist in Textform zu erkl&auml;ren.</p>
            <p>4.5 Preisaktionen</p>
            <p>4.5.1 Die von Flix SE im Rahmen von Marketingaktivit&auml;ten durchgef&uuml;hrten Preisaktionen und der Kauf erm&auml;&szlig;igter Tickets sind auf 3 Tickets pro Person beschr&auml;nkt. Erwirbt eine Person mehr als 3 Tickets im Rahmen derselben Preisaktion, kann Flix SE alle Buchungen, die &uuml;ber die ersten 3 Tickets hinausgehen, stornieren. Auf diese Regelung kann bei speziellen Angeboten verzichtet werden.</p>
            <p>4.5.2 Im Rahmen einer Preisaktion erworbene Tickets, f&uuml;r die nach Ablauf der Aktion andere Bedingungen gelten, m&uuml;ssen zum vollen Preis erworben werden, der am Tag der Fahrt/G&uuml;ltigkeitstag des Tickets gilt.</p>
            <p>4.6 Unzul&auml;ssige Buchungspraxis</p>
            <p>4.6.1 Die kommerzielle Nutzung, insbesondere der Wiederverkauf von Tickets, ist nicht zul&auml;ssig und wird von Flix SE sanktioniert, indem Tickets gesperrt und/oder Schadensersatzanspr&uuml;che geltend gemacht werden.</p>
            <p>4.6.2 Im Falle einer strafbaren Handlung, wie Betrug oder T&auml;uschungsversuchen, in Verbindung mit dem Kauf, der R&uuml;cknahme oder der &Uuml;bertragung von Tickets beh&auml;lt sich Flix SE das Recht vor, das zugeh&ouml;rige Kundenkonto zu schlie&szlig;en und/oder eine alternative Zahlungsmethode zu verlangen und/oder die Tickets ung&uuml;ltig zu machen. Ein Anspruch auf Entwertung bzw. Einl&ouml;sung des jeweiligen Tickets besteht f&uuml;r Flix SE nicht.</p>
            <p>4.6.3 Im Falle einer strafbaren Handlung, wie Betrug oder T&auml;uschungsversuchen, in Verbindung mit dem Kauf, der R&uuml;cknahme oder der &Uuml;bertragung von Tickets beh&auml;lt sich Flix SE das Recht vor, Tickets zu stornieren, die infolge einer Stornierung neu ausgestellt oder umgebucht wurden.</p>
            <p>5 Stornierung und Umbuchung</p>
            <p>5.1 Eine Stornierung (= vertraglich einger&auml;umtes Gestaltungsrecht des Kunden, das die Aufhebung des bestehenden Bef&ouml;rderungsvertrages zur Folge hat und vom Kunden auch dann ausge&uuml;bt werden kann, wenn ihm kein anderweitiges gesetzliches oder vertragliches Gestaltungsrecht zur Verf&uuml;gung steht, welches einen Anspruch auf ganz oder teilweise R&uuml;ckzahlung des Bef&ouml;rderungsentgeltes zur Folge h&auml;tte) kann vor der Abfahrt gem&auml;&szlig; 5.2 bis 5.5 erfolgen. Eine &Auml;nderung oder Stornierung der Buchung beim Fahrer ist nicht m&ouml;glich.</p>
            <p>S&auml;mtliche weiteren gesetzlichen Anspr&uuml;che bleiben neben dem Recht auf Stornierung bestehen und werden durch dieses nicht eingeschr&auml;nkt.</p>
            <p>Umbuchungen k&ouml;nnen nur in der Art erfolgen, dass der urspr&uuml;nglich abgeschlossene Bef&ouml;rderungsvertrag durch Stornierung oder unter Aus&uuml;bung eines gesetzlichen Gestaltungsrechts aufgehoben und ein neuer Bef&ouml;rderungsvertrag abgeschlossen wird.</p>
            <p>5.2 Eine Stornierung kann ausschlie&szlig;lich auf FlixBus Websites oder durch Partneragenturen und Ticket-Verkaufsstellen von FlixGesellschaften bis zu 15 Minuten vor der geplanten Abfahrtszeit vorgenommen werden. Eine Hin- und R&uuml;ckfahrt gilt als eine Buchung.</p>
            <p>5.3 Im Falle einer Stornierung wird ein sogenannter Storno-Gutschein ausgestellt. Dieser Storno-Gutschein ist 12 Monate ab Ausstellungsdatum g&uuml;ltig und berechtigt den Fahrgast, innerhalb dieser Zeit in H&ouml;he des Gutscheinwertes eine neue Buchung vorzunehmen. Liegt der Preis der neuen Buchung &uuml;ber dem Gutscheinwert, so ist der Differenzpreis zu entrichten. Liegt er darunter, so bleibt der Restbetrag des Storno-Gutscheins erhalten und kann bei einer anderen Buchung verwandt bzw. aufgebraucht werden. Bei zeitlich begrenzten Aktionsangeboten k&ouml;nnen Abweichungen von diesen Regelungen erfolgen. N&auml;heres regeln die gesonderten auf den Webportalen abrufbaren Aktionsbedingungen.&nbsp;</p>
            <p>Storno-Gutscheine, die vor dem 09.11.2019 erstellt wurden unterliegen der gesetzlichen Verj&auml;hrung.</p>
            <p>5.4 Je Stornierungsvorgang kann eine Stornierungsgeb&uuml;hr je stornierter Fahrt und je Fahrgast erhoben werden. Der Storno-Gutschein wird in H&ouml;he des Ticketpreises abz&uuml;glich der Kosten f&uuml;r den Stornierungsvorgang ausgestellt. Im Falle einer &Auml;nderung des Fahrgastnamens muss die Differenz bezahlt werden, wenn der Fahrpreis in der Zwischenzeit gestiegen ist. Die &Auml;nderung der Telefonnummer ist kostenlos.</p>
            <p>5.5 Falls ein Stornierungsgutschein f&uuml;r eine Buchung verwendet wird, gelten die gleichen Stornierungsbedingungen auch f&uuml;r die neue Reise.</p>
            <p>5.6 Wird ein Ticket, f&uuml;r welches die FlixGesellschaften Bef&ouml;rderer sind, nicht zur Fahrt benutzt, so wird das Bef&ouml;rderungsentgelt auf Antrag gegen Vorlage des Tickets abz&uuml;glich eines Bearbeitungsentgelts je Fahrt und Fahrgast erstattet, sofern nicht vom Fahrgast nachgewiesen werden kann, dass ein Schaden nicht oder in einer niedrigeren H&ouml;he angefallen ist. Die Beweislast f&uuml;r die Nichtbenutzung des Tickets liegt beim Fahrgast. Pro Person und Fahrt wird ein Ticket generiert. Umsteigeverbindungen werden als eine Fahrt angesehen. Der Antrag ist formlos und innerhalb von 3 Monaten m&ouml;glich. Er ist an Flix oder FlixBus DACH, Postanschrift Flix SE, Friedenheimer Br&uuml;cke 16, 80639 M&uuml;nchen oder FlixBus DACH GmbH, Warschauer Platz 11-13, 10245 Berlin zu stellen. Das Bearbeitungsentgelt wird auf einen Betrag von 2 &euro; (oder den entsprechenden Betrag in der jeweiligen Landesw&auml;hrung) je Fahrgast und Fahrt zuz&uuml;glich etwaiger &Uuml;berweisungsgeb&uuml;hren reduziert, sofern der Bef&ouml;rderer die Flix SE oder die FlixBus DACH GmbH ist und der Antrag unverz&uuml;glich, sp&auml;testens jedoch innerhalb einer Woche nach Ablauf der G&uuml;ltigkeit des Tickets gestellt wird. 5.6. ist nicht anwendbar auf Tickets der FlixTrain GmbH.&nbsp;</p>
            <p>5.7 S&auml;mtliche oben genannten Bearbeitungsentgelte, Stornierungsgeb&uuml;hren und eine etwaige &Uuml;berweisungsgeb&uuml;hr fallen nicht an, wenn die Erstattung aufgrund von Umst&auml;nden beantragt wird, die die Flix SE oder die FlixGesellschaften zu vertreten haben. Die Gutschrift des Bef&ouml;rderungsentgelts abz&uuml;glich des etwaigen Bearbeitungsentgeltes sowie einer etwaigen &Uuml;berweisungsgeb&uuml;hr erfolgt ausschlie&szlig;lich auf das vom Kunden bei der Bestellung angegebene Konto, und bei Bezahlung per Kreditkarte auf dessen Kreditkartenkonto.</p>
            <p>5.8 Abweichende Stornierungsrichtlinien in den Allgemeinen Gesch&auml;ftsbedingungen der Bef&ouml;rderer sind nicht anwendbar.</p>
            <p>6 Servicegeb&uuml;hr</p>
            <p>F&uuml;r jede Buchung wird eine Servicegeb&uuml;hr von 0,99 EUR (oder den entsprechenden Betrag in der jeweiligen Landesw&auml;hrung) erhoben. Die Geb&uuml;hr wird pro Buchung erhoben und gilt f&uuml;r die Nutzung der Buchungswebsite oder der App sowie f&uuml;r andere kundenorientierte Tools, wie z. B. &bdquo;Meine Buchung verwalten / Selbstbedienungstool zum &Auml;ndern von Reisen, Hinzuf&uuml;gen von Gep&auml;ck&ldquo;.</p>
            <p>Im Falle einer Stornierung von Seiten des Fahrgastes wird die Servicegeb&uuml;hr nicht zur&uuml;ckerstattet. Bei telefonischen Buchungen (&uuml;ber den Kundendienst) oder in einer unserer Agenturen und offiziellen Offline-Ticketverkaufsstellen k&ouml;nnen zus&auml;tzliche Geb&uuml;hren anfallen.</p>
            <p>7 Gerichtsstand</p>
            <p>Gerichtsstand f&uuml;r Vollkaufleute, juristische Personen und nat&uuml;rliche Personen, die keinen allgemeinen Gerichtsstand im Inland haben, sowie f&uuml;r nat&uuml;rliche Personen, die nach Abschluss eines Bef&ouml;rderungsvertrages ihren Wohnsitz oder ihren gew&ouml;hnlichen Aufenthaltsort ins Ausland verlegt haben, deren Wohnsitz oder gew&ouml;hnlicher Aufenthaltsort zum Zeitpunkt der Klageerhebung nicht bekannt ist, ist M&uuml;nchen.</p>
            <p>8 Unwirksamkeit einzelner Bestimmungen</p>
            <p>Sollten einzelne Bestimmungen innerhalb dieser Allgemeinen Gesch&auml;ftsbedingungen und Besonderen Buchungsbedingungen ganz oder teilweise unwirksam oder ung&uuml;ltig sein bzw. werden, beeintr&auml;chtigt dies grunds&auml;tzlich nicht die Wirksamkeit des Vertrages als Ganzes.&nbsp;</p>
            <p>II Abweichende L&auml;nderbestimmungen</p>
            <p>1. Schweden</p>
            <p>zu 4.1: In Schweden werden an Bord der Fahrzeuge nur Kartenzahlungen akzeptiert (MasterCard, Visa, American Express, Maestro, Visa Electron, V Pay, JCB, Diners Club, China Union Pay). Au&szlig;erdem kann in Schweden mit mobilen, NFC basierten Zahlungssystemen das Ticket beim Busfahrer erworben werden. (z. Bsp. Swish, Mobile Pay, Apple Pay, Android Pay).</p>
            <p>2. Polen</p>
            <p>zu 4.1: An Bord der Fahrzeuge werden nur Kartenzahlungen akzeptiert (Visa, V Pay, MasterCard, Maestro JCB, PolCard, PolCard BIS, Diners Club, American Express) und das Bezahlsystem BLIK f&uuml;r Verbindungen, die von der FlixBus Polska sp. z o.o. durchgef&uuml;hrt werden.</p>
            <p>zu 4.4.1, 4.4.2, 5.6: Diese Bestimmungen finden keine Anwendung f&uuml;r Verbindungen, die von der FlixBus Polska sp. z.o.o. durchgef&uuml;hrt werden.</p>
            <p>zu 5.1: Eine Stornierung kann nicht durch die Nichtbenutzung des Tickets und anschlie&szlig;ender Erstattung f&uuml;r Verbindungen erfolgen, die von der FlixBus Polska sp. z o.o. oder einem anderen Bef&ouml;rderer mit eingetragenem Firmensitz in Polen durchgef&uuml;hrt werden.</p>
            <p>zu 5.4: Eine Stornierungsgeb&uuml;hr gilt nicht f&uuml;r Verbindungen, die von FlixBus Polska sp. z o.o. oder einem anderen Bef&ouml;rderer mit eingetragenem Firmensitz in Polen durchgef&uuml;hrt werden.</p>
            <p>zu 6: F&uuml;r Zahlungen in polnischen Zloty betr&auml;gt die Servicegeb&uuml;hr 2,99 PLN.</p>
            <p>3. Tschechische Republik</p>
            <p>zu 5.4: Die Stornogeb&uuml;hr gilt nicht f&uuml;r in tschechischen Kronen gezahlte Tickets.</p>
            <p>zu 7: Potentielle Rechtsstreite zwischen FlixBus CZ s.r.o. und Fahrg&auml;sten werden vor ein Gericht in der Tschechischen Republik gebracht.</p>
            <p>4. Vereinigtes K&ouml;nigreich</p>
            <p>zu 5.6: Die Erstattung von abgelaufenen Fahrscheinen gilt nicht f&uuml;r Verbindungen, die von FlixBus UK Limited oder einem anderen Bef&ouml;rderer mit eingetragenem Firmensitz in Gro&szlig;britannien durchgef&uuml;hrt werden.</p>
            <p>5. Frankreich</p>
            <p>zu 5.6: Die Erstattung von abgelaufenen Fahrscheinen gilt nicht f&uuml;r Verbindungen innerhalb von Frankreich, die von FlixBus France SARL oder einem anderen Bef&ouml;rderer mit eingetragenem Firmensitz in Frankreich durchgef&uuml;hrt werden.</p>
            <p>6. Portugal</p>
            <p>zu 5.6: Die Erstattung von abgelaufenen Fahrscheinen gilt nicht f&uuml;r Verbindungen innerhalb von Portugal, die von FlixBus Portugal Unipessoal LDA oder einem anderen Bef&ouml;rderer mit eingetragenem Firmensitz in Portugal durchgef&uuml;hrt werden.</p>
            <p>7. Ukraine</p>
            <p>zu 4.5.1: Der im Online-Shop angegebene Gesamtpreis der Fahrkarte umfasst alle mit der Zahlung verbundenen Geb&uuml;hren, die von Drittanbietern (von FlixBus aus) f&uuml;r die von Ihnen gew&auml;hlte Zahlungsmethode erhoben werden, sowie die gesetzliche Umsatzsteuer.&nbsp;</p>
            <p>Ungeachtet dessen k&ouml;nnen zus&auml;tzlich zu dem im Online-Shop angegebenen, vom Kunden zu zahlenden Gesamtpreis weitere Geb&uuml;hren der Bank oder des Finanzinstituts des Kunden hinzukommen, die gem&auml;&szlig; Richtlinien der Bank/des Finanzinstituts oder geltenden Gesetzen berechnet werden, ohne dass dies FlixBus bekannt ist oder FlixBus darauf Einfluss hat. Solche Geb&uuml;hren k&ouml;nnen zum Beispiel f&uuml;r eine W&auml;hrungsumrechnung anfallen, die von der Bank/dem Finanzinstitut des Kunden durchgef&uuml;hrt wird. Daher best&auml;tigen die Vertragsparteien, dass zu dem vom Kunden zu zahlenden Gesamtpreis weitere Geb&uuml;hren hinzukommen k&ouml;nnen, f&uuml;r die FlixBus nicht verantwortlich ist.</p>
            <p>8. D&auml;nemark</p>
            <p>zu 4.1: In D&auml;nemark k&ouml;nnen Fahrausweise nicht an Bord der Fahrzeuge gekauft werden.</p>
        </Container>
    </>);
}
export default PassRights
